import React, { useEffect, useState } from "react";
import { searchQualification } from "services/serp";
import { entityType, filtersMyracle } from "utils/commons";
import SearchView from "components/SearchView";
import useLoadData from "../../hooks/useLoadData";
import "./style.scss";

const Quals = ({
  searchPreset,
  setSearch,
  updateCount,
  handleResetQ,
  callback,
}) => {
  const [selectedFilters, setSelectedFilters] = useState({
    order_by: "RANKING",
    ...searchPreset,
  });

  const {
    loadData,
    items: quals,
    setItems: setQuals,
    total,
    setPageNumber,
  } = useLoadData({
    searchFunction: searchQualification,
    params: { ...selectedFilters },
  });

  const handleResetFilters = () => {
    setPageNumber(0);
    setQuals([]);

    const initialFilter = {
      order_by: "RANKING",
    };
    setSelectedFilters(initialFilter);
    setSearch("quals", initialFilter);
    handleResetQ("quals");
  };
  const handleChangeFilter = (key, value) => {
    setPageNumber(0);
    setQuals([]);

    setSelectedFilters({
      ...selectedFilters,
      [key]: value,
    });
    setSearch("quals", {
      ...selectedFilters,
      [key]: value,
    });
  };

  useEffect(() => {
    updateCount("quals", total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  useEffect(() => {
    setPageNumber(0);
    const newFilter = { ...selectedFilters, ...searchPreset };
    if (JSON.stringify(selectedFilters) === JSON.stringify(newFilter)) {
      return;
    }
    setSelectedFilters({ ...selectedFilters, ...searchPreset });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPreset]);

  return (
    <SearchView
      selectedFilters={selectedFilters}
      handleChangeFilter={handleChangeFilter}
      dataList={quals}
      viewableFilters={filtersMyracle.QUALS}
      isSerp
      handleResetFilters={handleResetFilters}
      callback={callback}
      documentType={entityType.QUALIFICATION}
      loadData={loadData}
      total={total}
    />
  );
};

export default Quals;
