import React, { useMemo } from "react";
import bo_file from "assets/backoffice_quickguide.pdf";
import fo_file from "assets/frontoffice_quickguide.pdf";
import video_pr from "assets/video/Myracle tutorial_v1.0_BR_low quality.mp4";
import video_en from "assets/video/Myracle tutorial_v1.0_EN_low quality.mp4";
import video_sp from "assets/video/Myracle tutorial_v1.0_ES_low quality.mp4";
import video_it from "assets/video/Myracle tutorial_v1.0_ITA_low quality.mp4";
import AnchorTabs from "components/AnchorTabs";
import ContactCard from "components/HowToContactCard";
import HowToDownload from "components/HowToDownload";
import HowToSection from "components/HowToSections";
import VideoCard from "components/HowToVideoCard";
import TitleContainerWrapper from "components/TitleContainerWrapper";
import sticker_contacts from "images/sticker_contacts.svg";
import sticker_how from "images/sticker_how.svg";
import sticker_pc from "images/sticker_pc.svg";
import sticker_phone from "images/sticker_phone.svg";
import sticker_what from "images/sticker_what.svg";
import {
  HOWTO_PAGE_SECTION_WHO,
  HOWTO_PAGE_SECTION_WHAT,
  HOWTO_PAGE_SECTION_DOWNLOAD_DEMO,
  HOWTO_PAGE_SECTION_DOWNLOAD_GUIDE,
  HOWTO_PAGE_SECTION_CONTACTS,
  HOWTO_YOUTUBEURL,
} from "./definition";
import "./style.scss";

const HowTo = () => {
  const linksDemo = useMemo(
    () => [
      {
        label: "download english version",
        href: video_en,
        key: "video_en",
      },
      {
        label: "download spanish version",
        href: video_sp,
        key: "video_sp",
      },
      {
        label: "download portugese version",
        href: video_pr,
        key: "video_pr",
      },
      {
        label: "download italian version",
        href: video_it,
        key: "video_it",
      },
    ],
    []
  );

  const linksGuide = useMemo(
    () => [
      {
        label: "DOWNLOAD QUICKGUIDE FOR BROWSING USERS",
        href: fo_file,
        key: "fo_file",
      },
      {
        label: "DOWNLOAD QUICKGUIDE FOR EDITOR USERS",
        href: bo_file,
        key: "bo_file",
      },
    ],
    []
  );

  const myracleVideos = useMemo(
    () => [
      {
        url: "https://www.youtube.com/embed/xcJtL7QggTI",
        name: "Paolo Re",
        occupation: "BIP Brazil, Country Manager",
        pitch: "Rapidly choose Quals for presales",
      },
      {
        url: "https://www.youtube.com/embed/mkggXE5e2yk",
        name: "Paolo Re",
        occupation: "BIP Brazil, Country Manager",
        pitch: "Rapidly choose Quals for presales",
      },
      {
        url: "https://www.youtube.com/embed/K4TOrB7at0Y",
        name: "Paolo Re",
        occupation: "BIP Brazil, Country Manager",
        pitch: "Rapidly choose Quals for presales",
      },
    ],
    []
  );

  const videoContainer = useMemo(
    () => (
      <div className="myracle-videos">
        {myracleVideos.map(({ url, name, occupation, pitch }) => (
          <VideoCard key={url} url={url} name={name} occupation={occupation} pitch={pitch} />
        ))}
      </div>
    ),
    [myracleVideos]
  );

  const contacts = useMemo(
    () => [
      {
        title: "User Support",
        description: ["For problems related to: ",<ul><li>master data management (e.g. add a new customer/technology, business challenges, ...)</li><li>basic Myracle literacy (help on how to use the platform or a specific functionality)</li><li>request for new Organisational Unit onboarding</li></ul>],
        email: "myracle-dbcurator@bip-group.com",
      },
      {
        title: "Technical Support",
        description: ["For problems related to:",<ul><li>user creation/enablement (access problems, user grants/visibility, ...)</li><li>other technical problems/bugs or malfunctioning</li></ul>],
        email: "myracle-support@bip-group.com",
      },
    ],
    []
  );
  const contactCards = useMemo(
    () => (
      <div className="contact-cards">
        {contacts.map(({title, description, email}) => (
          <ContactCard key={title} title={title} description={description} email={email} />
        ))}
      </div>
    ), [contacts]);

  return (
    <div className="container container-how-to">
      <TitleContainerWrapper blueVariant>
        <AnchorTabs />
        <HowToSection
          id="who"
          type="How To"
          title="What is Myracle and how it works"
          subtitle={HOWTO_PAGE_SECTION_WHO}
          sticker={{
            image: sticker_what,
            height: 144,
            width: 364,
          }}
          content={
            <>
              <VideoCard url={HOWTO_YOUTUBEURL} />
              <div className="downloads">
                <HowToDownload
                  title="Download Myracle Demo"
                  subtitle={HOWTO_PAGE_SECTION_DOWNLOAD_DEMO}
                  linkList={linksDemo}
                />
                <HowToDownload
                  title="Download Myracle Quickguide"
                  subtitle={HOWTO_PAGE_SECTION_DOWNLOAD_GUIDE}
                  linkList={linksGuide}
                />
              </div>
            </>
          }
        />
        {/*
         TODO: scommentare quando arriveranno le info dal cliente per il popolamento di questa sezione
        <HowToSection
          id="what"
          type="How To"
          title="What Myracle does"
          subtitle={HOWTO_PAGE_SECTION_WHAT}
          sticker={{
            image: sticker_how,
            height: 162,
            width: 305,
          }}
          content={videoContainer}
        />*/}
        <HowToSection
          id="contacts"
          type="How To"
          title="Contacts"
          subtitle={HOWTO_PAGE_SECTION_CONTACTS}
          sticker={{
            image: sticker_contacts,
            height: 185,
            width: 364,
          }}
          content={contactCards}
        />
        <div className="container-how-to__bottom-stickers">
          <img src={sticker_phone} alt={sticker_phone} width={120} height={172} />
          <img src={sticker_pc} alt={sticker_pc} width={123} height={172} />
        </div>
      </TitleContainerWrapper>
    </div>
  );
};

export default HowTo;
