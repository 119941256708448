export const HOWTO_YOUTUBEURL = "https://www.youtube.com/embed/LV7PSuRbqLo";
export const HOWTO_PAGE_SECTION_WHO = "Myracle is a powerful AI-based Document Management System that enables all Market Facing Units to access BIP’s expertise, providing support in scouting opportunities and engaging clients. Designed to easily generate and search company’s Offering documents and Success Stories, categorized by Industry. Browse by Organizational Units, Industries, Business Challenges or Client Personas. Search by Offerings, Qualifications or through cognitive search.";
export const HOWTO_PAGE_SECTION_DOWNLOAD_DEMO =
  "A 15 minutes recorded demo to go straight to the point and quickly discover all about Myracle.";
export const HOWTO_PAGE_SECTION_DOWNLOAD_GUIDE =
  "Download one page Quickguides to learn how to start using Myracle in a few steps. For users who search/browse/download and for users who create/edit new documents";
export const HOWTO_PAGE_SECTION_WHAT =
  "Check out some videos from our Myracle Ambassadors, who take you through the main functionalities and what they helped them achieve";
export const HOWTO_PAGE_SECTION_CONTACTS =
  "Find  below your key contacts to address your enquiries related to Myracle platform";
