import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { connect, useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import { auditActions, auditTypes } from "utils/commons";
import { getProfileInfo } from "utils/profile";
import LegendHeadbar from "components/LegendHeadbar";
import ContentCard from "../ContentCard";

import "./style.scss";

const CardList = ({
  elemList,
  profile,
  isContributor,
  callback,
  documentType,
  loadData,
  total,
  newTreeView,
  isLoadingData = false,
}) => {
  const { Track, trackEvent } = useTracking();
  //define roles
  const { isAdmin, isEditor, isDownloader, canLike } = getProfileInfo(profile);
  const editScope = isEditor && {
    orgIds: isAdmin ? "all" : profile.organizationalUnitIds,
  };

  const organizationalUnits = useSelector(state => state.organizationalUnits);
  const unitsColorMap = useSelector((state) => state.unitsColor);

  const [unitsColor, setUnitsColor] = useState({});

  useEffect(() => {
    setUnitsColor(unitsColorMap)
  }, [unitsColorMap]);

  const onScroll = () => {
    trackEvent({
      action: auditActions.LOAD_MORE,
      type: auditTypes.CONTENT,
    });
    loadData();
  };

  const isDraft = elem => {
    return elem?.versionType && elem?.versionType === "DRAFT";
  };

  return (
    <>
        <LegendHeadbar legend={unitsColor}/>
      <div
        className={
          newTreeView
            ? "results-cards-contributor scroll-down"
            : "results-cards scroll-down"
        }>
        {elemList?.map((elem, index) => {
          const unitsIds = (elem.secondaryOrganizationalUnitsIds) ?
             [elem.organizationalUnitId].concat(elem?.secondaryOrganizationalUnitsIds) :
             [elem.organizationalUnitId]
          let categories = []
          for (let id of unitsIds) {
            const index = organizationalUnits.findIndex(obj => obj.id === id);
            const type =(organizationalUnits[index]?.type === 'MFU' && organizationalUnits[index].isForeign) ? 'MFU_ESTERE' : organizationalUnits[index]?.type
            categories.push(type)
          }
          return(
            <Track key={`${elem.code}-${index}`}>
              <ContentCard
                callback={callback}
                key={`${elem.code}-${index}`}
                isContributor={isContributor}
                isDownloader={isDownloader}
                canLike={canLike}
                userEditScope={editScope}
                isDraft={isDraft(elem)}
                documentType={documentType}
                {...elem}
                unitsColor={unitsColor}
                categories={categories}
              />
            </Track>
          )}
        )}
        {elemList?.length && total > elemList.length && (
          <div className="load-more">
            <button disabled={isLoadingData} onClick={onScroll}>{isLoadingData ? 'Loading...' : 'Load More'}</button>
          </div>
        )}
      </div>
    </>
  );
};

export default connect(
  ({ profile, quals, offerings, businessChallenges }) => ({
    profile,
    quals,
    offerings,
    businessChallenges,
  }),
  () => ({})
)(CardList);
