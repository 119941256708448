import React, { useCallback, useState } from "react";
import MidArrow from "images/arrow.svg";
import CustomOption from "../CustomOption";

import "./style.scss";

const CustomOUSelect = ({
  value,
  items = [],
  onChange,
  label,
  isMultiple,
  isAutocomplete,
  onSearch = false,
  absoluteOptions,
  byCode = false,
  numberSelected = undefined,
  noNumber = false,
}) => {
  const [openOptions, setOpenOptions] = useState(false);

  const { matches: isMobile } = window.matchMedia("(max-width: 912px)");

  const handleOnChange = useCallback(
    (val, checked) => {
      if (onChange) {
        if (isMultiple) {
          if (!checked) {
            onChange(value.filter(e => e !== val));
          } else {
            onChange([...value, val]);
          }
        } else {
          if (!checked) {
            onChange(null);
          } else {
            onChange(val);
          }
        }
      }
    },
    [isMultiple, onChange, value]
  );

  const mappedItems = items?.map(i => (
    <CustomOption
      byCode={byCode}
      key={i.id}
      onClick={handleOnChange}
      value={i.value}
      id={i.id}
      type={i.type}
      code={i.code}
      selected={
        !byCode
          ? Array.isArray(value)
            ? value.includes(i.value)
            : i.value === value
          : Array.isArray(value)
          ? value.includes(i.code)
          : i.code === value
      }
    />
  ));

  return (
    <div className="custom-select-wrapper ou">
      <div
        onClick={() => setOpenOptions(!openOptions)}
        className="custom-select-input">
        {noNumber ? (
          <div className="custom-select-label-wrapper ou no-number">
            {!isAutocomplete && !onSearch && (
              <div className="label">{label}</div>
            )}
          </div>
        ) : (
          <div className="custom-select-label-wrapper ou">
            {!isAutocomplete && !onSearch ? (
              <div className="label">{label}</div>
            ) : (
              <div className="label">{""}</div>
            )}
            {Array.isArray(value) && value.length > 0 ? (
              <div className="filter-number">
                {numberSelected ?? value.length}
              </div>
            ) : !Array.isArray(value) && value ? (
              <div className="filter-number">1</div>
            ) : (
              ""
            )}
          </div>
        )}

        <div className="custom-select-input-arrow">
          <img src={MidArrow} alt="arrow-control" />
        </div>
      </div>

      {openOptions && isMobile && (
        <div
          className={`custom-select-options ${
            absoluteOptions ? "absolute" : ""
          }`}>
          {mappedItems}
        </div>
      )}
      {openOptions && (!isAutocomplete || !isMobile) && (
        <div
          className={`custom-select-options ${
            absoluteOptions ? "absolute" : ""
          }`}>
          <div
            onClick={() => setOpenOptions(!openOptions)}
            className="custom-select-input">
            <div className="custom-select-input-arrow">
              <img src={MidArrow} alt="arrow-control" />
            </div>
            {label}
          </div>
          {mappedItems}
        </div>
      )}
    </div>
  );
};

export default CustomOUSelect;
