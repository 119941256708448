import React from "react";
import { useTypography } from "components/Typography";
import "./style.scss";

const VideoCard = ({ url, name, occupation, pitch }) => {
  const { RegularBold, TextSmall } = useTypography();
  return (
    <div className="video-container">
      <iframe
        src={url}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
      {
        (name || occupation) &&
        <div className="info">
          {name && <RegularBold>{name}</RegularBold>}
          {occupation && <TextSmall>{occupation}</TextSmall>}
          {pitch && <TextSmall>{pitch}</TextSmall>}
        </div>
      }
    </div>
  );
};

export default VideoCard;
