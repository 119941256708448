import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { POPULATE_BUSINESS_CHALLENGE } from "redux/businessChallenge";
import { GET_DICTIONARY } from "redux/dictionaries";
import { GET_GROUPS } from "redux/groups";
import { GET_INDUSTRIES } from "redux/industries";
import { SET_INDUSTRY_TREE } from "redux/industryTree";
import { GET_ORGANIZATIONAL_UNITS } from "redux/organizationalUnits";
import { GET_PERSONAS } from "redux/personas";
import { GET_ROLES } from "redux/roles";
import { GET_SUBINDUSTRIES } from "redux/subindustries";
import { elapsed, fte, yearFilters, projectTypes } from "utils/commons";
import { getNumberActiveFilters, objectToArray } from "utils/utils";
import CustomDatePicker from "components/CustomDatePicker";
import CustomSelect from "components/CustomSelect";
import { ReactComponent as CloseIcon } from "images/cancel.svg";
import CustomIndustrySelect from "../CustomIndustrySelect";
import CustomOUSelect from "../CustomOUSelect";

import "./style.scss";

const Filters = ({
  groups,
  roles,
  dictionaries,
  personas,
  organizationalUnits,
  getOrganizationalUnits,
  getPersonas,
  getDictionary,
  getRoles,
  getGroups,
  handleChangeFilter,
  viewableFilters,
  selectedFilters = {},
  closeFilters,
  numberItems,
  handleResetFilters,
  businessChallenges,
  populateBC,
  filterDateFrom = () => true,
  filterDateTo = () => true,
  rangeError,
  isCoeMultiple = true,
  industryTree,
  subindustries,
  industries,
  getIndustries,
  getSubIndustries,
  setIndustryTree,
  offering = false,
}) => {
  useEffect(() => {
    const populateFilters = async () => {
      await getDictionary({ type: "COUNTRY" });
      await getDictionary({ type: "EPIC" });
      await getDictionary({ type: "TECHNOLOGY" });
      await getDictionary({ type: "CUSTOMER" });
      await getPersonas();
      await getOrganizationalUnits();
      if (viewableFilters.includes("group")) {
        await getGroups();
      }
      if (viewableFilters.includes("roles")) {
        await getRoles();
      }
    };
    populateFilters();
  }, [
    getDictionary,
    getPersonas,
    getOrganizationalUnits,
    populateBC,
    getGroups,
    getRoles,
    viewableFilters,
  ]);

  useEffect(() => {
    if (subindustries.length > 0 && industries.length > 0) {
      setIndustryTree({ industries, subindustries });
      setIndustryArray(objectToArray(industryTree));
    } else {
      getIndustries();
      getSubIndustries();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industries, subindustries]);

  const [industryArray, setIndustryArray] = useState([]);
  const [subIndustry, setSubIndustry] = useState([]);
  const [subIndustrySel, setSubIndustrySel] = useState();

  useEffect(() => {
    if (Array.isArray(selectedFilters.subindustry)) {
      setSubIndustrySel([...selectedFilters.subindustry]);
      setSubIndustry([
        ...subIndustry.filter(subObj =>
          selectedFilters.subindustry.includes(subObj.code)
        ),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters.subindustry]);

  const ouTree = { COE: [], MFU_COE: [], MFU: [] };
  const [ouArray, setOuArray] = useState([]);

  useEffect(() => {
    organizationalUnits.length > 0
      ? organizationalUnits &&
        organizationalUnits.map(
          ou =>
            ou.type.toUpperCase() !== "SUBINDUSTRY" &&
            ouTree[ou.type.toUpperCase()].push(ou)
        )
      : getOrganizationalUnits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => setOuArray(objectToArray(ouTree)), // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [filteredBC, setFilteredBC] = useState([]);

  useEffect(() => {
    setFilteredBC(
      Object.keys(businessChallenges).map(k => ({
        id: k,
        value: businessChallenges[k].description,
      }))
    );
  }, [businessChallenges]);

  const changeFilter = filter => value => {
    if (handleChangeFilter) handleChangeFilter(filter, value);
  };

  const activeFilters = getNumberActiveFilters(selectedFilters);

  return (
    <>
      <div className="filter-label">
        {"FILTERS"}
        <div className="close-filters" onClick={closeFilters}>
          <CloseIcon alt="close filters" />
        </div>
      </div>
      {activeFilters !== 0 && (
        <div className="filter-reset">
          <div className="link-reset" onClick={handleResetFilters}>
            RESET FILTERS
          </div>
        </div>
      )}
      <div className="filter-content">
        {viewableFilters.includes("industry") && offering ? (
          <CustomOUSelect
            label="Industries"
            value={selectedFilters["organizational_unit"] || []}
            items={industryArray.map(v => {
              if (v.id) {
                return {
                  type: "option",
                  id: v.id,
                  value: v.name,
                  code: v.code,
                };
              }
              return {
                type: "title",
                id: v,
                value: industries.find(i => i.id === v).name,
                code: industries.find(i => i.id === v).code,
              };
            })}
            onChange={
              val => changeFilter("organizational_unit")(val)
              /*if (!subIndustry.some(sub => sub.id === id)) {
                  setSubIndustry([...subIndustry,
                    {
                      ...subindustries.find(sub => sub.id === id),
                      industry: industries.find(ind => ind.id === subindustries.find(sub => sub.id === id)?.industryId).name,
                    }
                  ]);
                  console.log(code, id);
                  return changeFilter("organizational_unit")([selectedFilters["organizational_unit"], code]);
                } else {
                  setSubIndustry([...subIndustry.filter(sub => sub.id !== id)])
                  return changeFilter("organizational_unit")([selectedFilters["organizational_unit"].map(sub => sub.code)]);
                }*/
            }
            byCode={true}
            isMultiple
            numberSelected={
              selectedFilters["organizational_unit"]?.filter(code =>
                industryArray.find(ou => ou.code === code)
              ).length ?? 0
            }
          />
        ) : viewableFilters.includes("industry") ? (
          <CustomIndustrySelect
            label="Client industry"
            value={subIndustrySel || []}
            items={industryArray.map(v => {
              if (v.id) {
                return {
                  type: "option",
                  id: v.id,
                  value: v.name,
                  code: v.code,
                };
              }
              return {
                type: "title",
                id: v,
                value: industries.find(i => i.id === v).name,
                code: industries.find(i => i.id === v).code,
              };
            })}
            onChange={(code, id) => {
              if (!subIndustry.some(sub => sub.id === id)) {
                setSubIndustry([
                  ...subIndustry,
                  {
                    ...subindustries.find(sub => sub.id === id),
                    industry: industries.find(
                      ind =>
                        ind.id ===
                        subindustries.find(sub => sub.id === id)?.industryId
                    ).name,
                  },
                ]);
                return changeFilter("subindustry")([
                  ...subIndustry.map(sub => sub.code),
                  code,
                ]);
              } else {
                setSubIndustry([...subIndustry.filter(sub => sub.id !== id)]);
                return changeFilter("subindustry")([
                  ...subIndustry
                    .filter(sub => sub.id !== id)
                    .map(sub => sub.code),
                ]);
              }
            }}
            isMultiple
          />
        ) : null}
        {viewableFilters.includes("customer") && (
          <CustomSelect
            label="Customer"
            value={selectedFilters.customer || ""}
            items={
              dictionaries?.CUSTOMER &&
              dictionaries?.CUSTOMER?.map(o => ({ id: o.id, value: o.name }))
            }
            onChange={val => changeFilter("customer")(val)}
            isAutocomplete
            isMultiple
          />
        )}
        {viewableFilters.includes("year") && (
          <>
            <CustomSelect
              label="Year from"
              value={selectedFilters.year_from || ""}
              items={yearFilters.map(y => ({ id: y, value: y }))}
              onChange={val => changeFilter("year_from")(val)}
            />
            <CustomSelect
              label="Year to"
              value={selectedFilters.year_to || ""}
              items={yearFilters.map(y => ({ id: y, value: y }))}
              onChange={val => changeFilter("year_to")(val)}
            />
          </>
        )}
        {viewableFilters.includes("organizational_unit") && offering ? (
          <CustomOUSelect
            byCode="true"
            label="Organizational units"
            value={selectedFilters["organizational_unit"] || []}
            items={
              ouArray &&
              ouArray
                .filter(ou => ou.type !== "MFU" && ou !== "MFU")
                .map(v => {
                  if (v.id) {
                    return {
                      type: "option",
                      id: v.id,
                      value: v.name,
                      code: v.code,
                    };
                  }
                  return {
                    type: "title",
                    id: v,
                    value: v,
                    code: "",
                  };
                })
            }
            onChange={val => changeFilter("organizational_unit")(val)}
            isMultiple={isCoeMultiple}
            closeOnSelect={!isCoeMultiple}
            numberSelected={
              selectedFilters["organizational_unit"]?.filter(code =>
                ouArray.find(ou => ou.code === code && ou.type !== "MFU")
              ).length ?? 0
            }
          />
        ) : viewableFilters.includes("organizational_unit") ? (
          <CustomOUSelect
            byCode="true"
            label="Organizational units"
            value={selectedFilters.organizational_unit || []}
            items={
              ouArray &&
              ouArray.map(v => {
                if (v.id) {
                  return {
                    type: "option",
                    id: v.id,
                    value: v.name,
                    code: v.code,
                  };
                }
                return {
                  type: "title",
                  id: v,
                  value: v,
                  code: "",
                };
              })
            }
            onChange={val => changeFilter("organizational_unit")(val)}
            isMultiple={isCoeMultiple}
            closeOnSelect={!isCoeMultiple}
          />
        ) : null}
        {viewableFilters.includes("group") && (
          <CustomSelect
            label="Group"
            value={selectedFilters.group || ""}
            items={groups.map(o => ({ id: o.id, value: o.name }))}
            onChange={val => changeFilter("group")(val)}
            isMultiple
          />
        )}
        {viewableFilters.includes("roles") && (
          <CustomSelect
            label="Roles"
            value={selectedFilters.roles || ""}
            items={roles.map(o => ({ id: o.id, value: o.name }))}
            onChange={val => changeFilter("roles")(val)}
            isMultiple
          />
        )}
        {viewableFilters.includes("personas") && (
          <CustomSelect
            label="Personas"
            value={selectedFilters.personas || ""}
            items={personas.map(o => ({ id: o.id, value: o.name }))}
            onChange={val => changeFilter("personas")(val)}
            isMultiple
            isAutocomplete
          />
        )}
        {viewableFilters.includes("epics") && (
          <CustomSelect
            label="Epics"
            value={selectedFilters.epics || ""}
            items={
              dictionaries?.EPIC &&
              dictionaries?.EPIC.map(o => ({ id: o.id, value: o.name }))
            }
            onChange={val => changeFilter("epics")(val)}
            isMultiple
            isAutocomplete
          />
        )}
        {viewableFilters.includes("trendy_topic") && (
          <CustomSelect
            label="Trendy topic"
            value={selectedFilters.trendy_topic || ""}
            items={[
              { id: "yes", value: "true", label: "yes" },
              { id: "no", value: "false", label: "no" },
            ]}
            onChange={val => changeFilter("trendy_topic")(val)}
            isAutocomplete
          />
        )}
        {viewableFilters.includes("elapsed") && (
          <CustomSelect
            label="Elapsed"
            value={selectedFilters.elapsed || ""}
            items={elapsed}
            onChange={val => changeFilter("elapsed")(val)}
          />
        )}
        {viewableFilters.includes("country") && (
          <CustomSelect
            label="Country"
            value={selectedFilters.countries || ""}
            items={
              dictionaries?.COUNTRY &&
              dictionaries?.COUNTRY.map(o => ({ id: o.id, value: o.name }))
            }
            onChange={val => changeFilter("countries")(val)}
            isMultiple
            isAutocomplete
          />
        )}
        {viewableFilters.includes("fte") && (
          <CustomSelect
            label="Fte"
            value={selectedFilters.fte || ""}
            items={fte}
            onChange={val => changeFilter("fte")(val)}
          />
        )}
        {viewableFilters.includes("technologies") && (
          <CustomSelect
            label="Technologies"
            value={selectedFilters.technologies || ""}
            items={
              dictionaries?.TECHNOLOGY &&
              dictionaries?.TECHNOLOGY.map(o => ({ id: o.id, value: o.name }))
            }
            onChange={val => changeFilter("technologies")(val)}
            isMultiple
            isAutocomplete
          />
        )}
        {viewableFilters.includes("project_types") && (
          <CustomSelect
            label="Project Types"
            value={selectedFilters.project_types || ""}
            items={projectTypes}
            onChange={val => changeFilter("project_types")(val)}
            isMultiple
          />
        )}
        {viewableFilters.includes("business_challenges") && (
          <CustomSelect
            label="Business Challenges"
            value={selectedFilters.business_challenges || ""}
            items={filteredBC}
            onChange={val => changeFilter("business_challenges")(val)}
            isMultiple
            isAutocomplete
          />
        )}
        {viewableFilters.includes("create_date_from") &&
          viewableFilters.includes("create_date_to") && (
            <div className="range-label">Created</div>
          )}
        {viewableFilters.includes("create_date_from") &&
          viewableFilters.includes("create_date_to") && (
            <>
              <div className="range-label">From:</div>
              <CustomDatePicker
                selected={selectedFilters.create_date_from || ""}
                onChange={val => changeFilter("create_date_from")(val)}
                filterDate={filterDateFrom}
              />
              <div className="range-label">To:</div>
              <CustomDatePicker
                selected={selectedFilters.create_date_to || ""}
                onChange={val => changeFilter("create_date_to")(val)}
                filterDate={filterDateTo}
              />
              {rangeError && <view className="range-error">{rangeError}</view>}
            </>
          )}
        {viewableFilters.includes("edit_date") && (
          <CustomDatePicker
            selected={selectedFilters.edit_date || ""}
            onChange={val => changeFilter("edit_date")(val)}
          />
        )}
      </div>

      <div className="filter-footer-button" onClick={closeFilters}>
        {activeFilters === 0 ? "Apply filters" : `Show ${numberItems} results`}
      </div>
    </>
  );
};

export default connect(
  ({
    dictionaries,
    personas,
    organizationalUnits,
    businessChallenges,
    groups,
    roles,
    industryTree,
    subindustries,
    industries,
  }) => ({
    dictionaries,
    personas,
    organizationalUnits,
    businessChallenges,
    groups,
    roles,
    industryTree,
    subindustries,
    industries,
  }),
  dispatch => ({
    getDictionary: payload => dispatch(GET_DICTIONARY(payload)),
    getPersonas: () => dispatch(GET_PERSONAS()),
    getOrganizationalUnits: () => dispatch(GET_ORGANIZATIONAL_UNITS()),
    populateBC: () => dispatch(POPULATE_BUSINESS_CHALLENGE()),
    getGroups: () => dispatch(GET_GROUPS()),
    getRoles: () => dispatch(GET_ROLES()),
    getIndustries: () => dispatch(GET_INDUSTRIES()),
    getSubIndustries: () => dispatch(GET_SUBINDUSTRIES()),
    setIndustryTree: payload => dispatch(SET_INDUSTRY_TREE(payload)),
  })
)(Filters);
