import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import About from "pages/About";
import BipUnitDetail from "pages/BipUnitDetail";
import BipUnits from "pages/BipUnits";
import BusinessChallenge from "pages/BusinessChallenge";
import BusinessChallengeDetail from "pages/BusinessChallengeDetail";
import ClientPersonas from "pages/ClientPersonas";
import ClientPersonasDetail from "pages/ClientPersonasDetail";
import CollaborationRules from "pages/CollaborationRules";
import Contributor from "pages/Contributor";
import contributor from "pages/Contributor";
import Events from "pages/Events";
import Home from "pages/Home";
import Offering from "pages/Offering";
import OfferingDetail from "pages/OfferingDetail";
import QualDetail from "pages/QualDetail";
import Quals from "pages/Quals";
import Serp from "pages/Serp";
import { GET_USER_BOOKMARKS } from "redux/bookmarks";
import { POPULATE_BUSINESS_CHALLENGE } from "redux/businessChallenge";
import { GET_DICTIONARY } from "redux/dictionaries";
import { GET_ORGANIZATIONAL_UNITS } from "redux/organizationalUnits";
import { GET_PERSONAS } from "redux/personas";
import { GET_PROFILE } from "redux/profile";
import routes from "routes";
import { acquireTokenSilent } from "services/config";
import { entityType } from "utils/commons";
import Layout from "components/Layout";
import LoadApp from "components/LoadApp";
import { NoLuck } from "components/Noluck";
import BackOfficeModal from "./components/BackOfficeModal";
import { useProfileContext } from "./contexts/Profile";
import AskContent from "./pages/AskContent";
import GroupsManagement from "./pages/GroupsManagement";
import HowTo from "./pages/HowTo";
import Industries from "./pages/Industries";
import IndustryDetail from "./pages/IndustryDetail";
import UsersManagement from "./pages/UsersManagement";
import { GET_INDUSTRIES } from "./redux/industries";
import { SET_INDUSTRY_TREE } from "./redux/industryTree";
import { GET_SUBINDUSTRIES } from "./redux/subindustries";


import "normalize.css";
import "./styles/main.scss";

const ContributorOfferings = () => (
  <Contributor type={entityType.OFFERING_TOPIC} contributor={contributor} />
);

const ContributorQuals = () => <Contributor type={entityType.QUALIFICATION} />;

const ContributorBusinessChallenges = () => (
  <Contributor type={entityType.BUSINESS_CHALLENGE} />
);

const AppRouter = () => {
  const dispatch = useDispatch();
  const getProfile = useCallback(() => dispatch(GET_PROFILE()), [dispatch]);
  const getOrganizationalUnits = useCallback(
    () => dispatch(GET_ORGANIZATIONAL_UNITS()),
    [dispatch]
  );
  const getIndustries = useCallback(
    () => dispatch(GET_INDUSTRIES()),
    [dispatch]
  );
  const getSubindustries = useCallback(
    () => dispatch(GET_SUBINDUSTRIES()),
    [dispatch]
  );
  const getBookmarks = useCallback(
    () => dispatch(GET_USER_BOOKMARKS()),
    [dispatch]
  );
  const getDictionary = useCallback(
    payload => dispatch(GET_DICTIONARY(payload)),
    [dispatch]
  );
  const getPersonas = useCallback(() => dispatch(GET_PERSONAS()), [dispatch]);
  const populateBC = useCallback(
    () => dispatch(POPULATE_BUSINESS_CHALLENGE()),
    [dispatch]
  );
  const populateIndustryTree = useCallback(
    payload => dispatch(SET_INDUSTRY_TREE(payload)),
    [dispatch]
  );

  const profile = useSelector(state => state.profile);
  const modal = useSelector(state => state.modal);
  const industries = useSelector(state => state.industries);
  const subindustries = useSelector(state => state.subindustries);

  const {
    canEditOfferings,
    canEditQuals,
    isEditor: accessToBackoffice,
    isAdmin: administrator,
    isViewer,
  } = useProfileContext();

  useEffect(() => {
    try {
      const initialMinimalStore = async () => {
        await acquireTokenSilent();
        await getOrganizationalUnits();
        await getProfile();
        getBookmarks();
        getDictionary({ type: "COUNTRY" });
        getDictionary({ type: "EPIC" });
        getDictionary({ type: "TECHNOLOGY" });
        getDictionary({ type: "CUSTOMER" });
        getIndustries();
        getSubindustries();
        getPersonas();
        populateBC();
      };
      void initialMinimalStore();
    } catch (error) {
      console.error(`AppRouter initialMinimalStore error: , ${error}`);
    }
  }, [
    getProfile,
    getOrganizationalUnits,
    getBookmarks,
    getDictionary,
    getPersonas,
    populateBC,
    getIndustries,
    getSubindustries,
  ]);

  useEffect(() => {
    if (industries.length && subindustries.length) {
      populateIndustryTree({ industries, subindustries });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industries, subindustries]);

  if (!Object.keys(profile).length) return <LoadApp />;

  if (profile && !isViewer) return <NoLuck />;

  return (
    <Router>
      <Layout>
        <Switch>
          <Route path={routes.HOME} exact component={Home} />
          <Route path={routes.OFFERING} exact component={Offering} />
          <Route
            path={`${routes.OFFERING}/:offeringCode`}
            exact
            component={OfferingDetail}
          />
          <Route path={routes.SERP} exact component={Serp} />
          <Route path={routes.QUALS} exact component={Quals} />
          <Route path={routes.BIP_UNITS} exact component={BipUnits} />
          <Route path={routes.INDUSTRIES} exact component={Industries} />
          <Route
            path={`${routes.BIP_UNITS}/:bipUnitId`}
            exact
            component={BipUnitDetail}
          />
          <Route
            path={`${routes.INDUSTRIES}/:industryId`}
            exact
            component={IndustryDetail}
          />
          <Route
            path={`${routes.QUALS}/:qualCode`}
            exact
            component={QualDetail}
          />
          <Route
            path={routes.CLIENT_PERSONAS}
            exact
            component={ClientPersonas}
          />
          <Route
            path={`${routes.CLIENT_PERSONAS}/:personaId`}
            exact
            component={ClientPersonasDetail}
          />
          <Route
            path={`${routes.BUSINESS_CHALLENGE}/:businessChallengeCode`}
            exact
            component={BusinessChallengeDetail}
          />
          <Route
            path={routes.BUSINESS_CHALLENGE}
            exact
            component={BusinessChallenge}
          />
          <Route path={routes.ASK_CONTENT} exact component={AskContent} />
          <Route path={routes.EVENTS} exact component={Events} />
          {accessToBackoffice && canEditQuals.length && (
            <Route
              path={routes.CONTRIBUTOR_QUALS}
              exact
              component={ContributorQuals}
            />
          )}
          {accessToBackoffice && canEditOfferings.length && (
            <Route
              path={routes.CONTRIBUTOR_OFFERINGS}
              exact
              component={ContributorOfferings}
            />
          )}
          {administrator && (
            <Route
              path={routes.CONTRIBUTOR_BUSINESS_CHALLENGES}
              exact
              component={ContributorBusinessChallenges}
            />
          )}
          {administrator && (
            <Route path={routes.ADMIN_USER} exact component={UsersManagement} />
          )}
          {administrator && (
            <Route
              path={routes.ADMIN_GROUP}
              exact
              component={GroupsManagement}
            />
          )}
          <Route path={routes.ABOUT} exact component={About} />
          <Route
            path={routes.COLLABORATION_RULES}
            exact
            component={CollaborationRules}
          />
          <Route path={routes.HOWTO} exact component={HowTo} />
          <Redirect to={routes.HOME} />
        </Switch>
        {modal?.isOpen && (
          <BackOfficeModal
            code={modal.modalProps.code}
            show={modal.isOpen}
            type={modal.modalProps.type}
            coe={modal.modalProps.selectedCoe}
            versionType={modal.modalProps.versionType}
          />
        )}
      </Layout>
    </Router>
  );
};

export default React.memo(AppRouter);
