import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import track from "react-tracking";
import { InteractionType } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  useMsalAuthentication,
} from "@azure/msal-react";
import { configureStore } from "@reduxjs/toolkit";
import AppRouter from "AppRouter";
import bookmarks from "redux/bookmarks";
import businessChallenges from "redux/businessChallenge";
import contributor from "redux/contributor";
import dictionaries from "redux/dictionaries";
import events from "redux/events";
import filters from "redux/filters";
import offerings from "redux/offerings";
import organizationalUnits from "redux/organizationalUnits";
import personas from "redux/personas";
import profile from "redux/profile";
import quals from "redux/quals";
import search from "redux/search";
import { postAudit } from "services/analitycs";
import { setAuthToken } from "services/config";
import config from "utils/config";
import Maintenance from "pages/Maintenance/index.js";
import { ProfileProvider } from "./contexts/Profile";
import { TreeProvider } from "./contexts/Tree";
import groups from "./redux/groups";
import industries from "./redux/industries";
import industryTree from "./redux/industryTree";
import modal from "./redux/modal";
import offeringsFilters from "./redux/offeringsFilters";
import qualificationsFilters from "./redux/qualificationsFilters";
import roles from "./redux/roles";
import subindustries from "./redux/subindustries";
import unitsColor from "./redux/unitsColor";
import users from "./redux/users";

import "normalize.css";
import "./styles/main.scss";
import "react-toastify/dist/ReactToastify.css";

const store = configureStore({
  reducer: {
    dictionaries,
    personas,
    organizationalUnits,
    unitsColor,
    search,
    quals,
    events,
    offerings,
    profile,
    bookmarks,
    businessChallenges,
    offeringsFilters,
    qualificationsFilters,
    filters,
    contributor,
    modal,
    users,
    roles,
    groups,
    industries,
    subindustries,
    industryTree,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: {
        warnAfter: 200,
      },
    }),
});

const prepareData = data => {
  const { type, path, ...rest } = data;
  return {
    type: type,
    path: path || " ",
    payload: { ...rest },
  };
};

const App = () => {
  const request = {
    scopes: [`${config.SSO_GETCODE_PARAMS.scope}`],
  };
  const { login, result, error } = useMsalAuthentication(
    InteractionType.Silent,
    request
  );

  useEffect(() => {
    if (error) {
      login(InteractionType.Redirect, request);
    }
    if (result) {
      setAuthToken(result.idToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, result]);

  return (
    <AuthenticatedTemplate>
      <Provider store={store}>
        <ProfileProvider>
          <TreeProvider>
            {document.env.ENABLE_MAINTENANCE ? (
              <Maintenance></Maintenance>
            ) : (
              <div>
                <AppRouter />
                <ToastContainer toastStyle={{ background: "red" }} />
              </div>
            )}
          </TreeProvider>
        </ProfileProvider>
      </Provider>
    </AuthenticatedTemplate>
  );
};

const TrackedApp = track(
  {},
  {
    dispatch: data => {
      setTimeout(() => postAudit(prepareData(data)), 3000);
    },
  }
)(App);

export default TrackedApp;
