import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTracking } from "react-tracking";
import { TRIGGER_MODAL, CLEAN_STORE } from "redux/modal";
import { RESET_FILTERS, SET_FILTERS } from "redux/offeringsFilters";
import { SET_SEARCH } from "redux/search";
import { deleteOffering } from "services/offeringTopic";
import { searchOfferingTopic } from "services/serp";
import {
  auditActions,
  auditTypes,
  entityType,
  filtersMyracle,
} from "utils/commons";
import HeroTitle from "components/HeroTitle";
import LegendButton from "components/LegendButton";
import SearchView from "components/SearchView";
import ConfirmActionModal from "components/ActionModal/ConfirmActionModal";
import useLoadData from "../../hooks/useLoadData";
import { GET_INDUSTRIES } from "../../redux/industries";
import { SET_INDUSTRY_TREE } from "../../redux/industryTree";
import { GET_SUBINDUSTRIES } from "../../redux/subindustries";

const Offering = () => {
  const dispatch = useDispatch();
  const setSearch = payload => dispatch(SET_SEARCH({ OFFERINGS: payload }));
  const updateModalProps = payload => dispatch(TRIGGER_MODAL(payload));
  const cleanStore = payload => dispatch(CLEAN_STORE(payload));
  const getIndustries = () => dispatch(GET_INDUSTRIES());
  const getSubindustries = () => dispatch(GET_SUBINDUSTRIES());
  const setIndustryTree = payload => dispatch(SET_INDUSTRY_TREE(payload));

  const searchOfferings = useSelector(state => state.searchOfferings);
  const industries = useSelector(state => state.industries);
  const subindustries = useSelector(state => state.subindustries);
  const modal = useSelector(state => state.modal);
  const industryTree = useSelector(state => state.industryTree);

  const { Track, trackEvent } = useTracking({
    resourceType: entityType.OFFERING_TOPIC,
  });
  const selectedFilters = useSelector(state => state.offeringsFilters);
  const setSelectedFilters = payload => dispatch(SET_FILTERS(payload));
  const resetSelectedFilters = () => dispatch(RESET_FILTERS());

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [code, setCode] = useState();

  const unitsColorMap = useSelector((state) => state.unitsColor);

  const {
    loadData,
    items: offerings,
    setItems: setOfferings,
    total,
    setPageNumber,
  } = useLoadData({
    searchFunction: searchOfferingTopic,
    params: { ...selectedFilters },
  });

  useEffect(() => {
    if (subindustries.length === 0) {
      getSubindustries();
    }
    if (industries.length === 0) {
      getIndustries();
    }
    if (industryTree.length === 0) {
      setIndustryTree({
        industries,
        subindustries,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (modal.shouldReloadOfferings) {
      setPageNumber(0);
      setOfferings([]);

      setSelectedFilters({
        order_by: "LATEST",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.shouldReloadOfferings]);

  useEffect(() => {
    setSelectedFilters({
        order_by: "LATEST",
        ...searchOfferings,
      })
  }, [])

  useEffect(() => {
    setSearch(selectedFilters);
    void loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  const handleResetFilters = () => {
    setPageNumber(0);
    setOfferings([]);

    resetSelectedFilters()
    setSelectedFilters({
      order_by: "LATEST",
    });
  };

  const handleChangeFilter = (key, value) => {
    setPageNumber(0);
    setOfferings([]);

    setSelectedFilters({
      ...selectedFilters,
      [key]: value,
    });
  };

  const offeringCallback = params => {
    const { action, code, organizationalUnit } = params;
    switch (action) {
      case "edit":
        updateModalProps({
          isOpen: true,
          modalProps: {
            code,
            type: entityType.OFFERING_TOPIC,
            selectedCoe: organizationalUnit,
          },
        });
        break;
      case "delete":
        setIsModalOpen(true);
        setCode(code);
        break;
      default:
        break;
    }
  };

  return (
    <div className="container">
      <HeroTitle
        type="Offering Topics"
        title="What we do for our clients"
        subtitle="List of the services we provide for our clients, with related descriptive metadata and attachments. Attachments are of two types: Detailed Offering Documents (available in protected mode, i.e. view only) and One-Pager short files (available in non protected mode, i.e. editable)"
        grayVariant
      />
      <Track>
        <SearchView
          selectedFilters={selectedFilters}
          handleChangeFilter={handleChangeFilter}
          dataList={offerings}
          viewableFilters={filtersMyracle.OFFERINGS}
          handleResetFilters={handleResetFilters}
          callback={offeringCallback}
          offering
          documentType={entityType.OFFERING_TOPIC}
          loadData={loadData}
          total={total}
        />
      </Track>
      <ConfirmActionModal
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => {
          deleteOffering(code)
            .then(res => {
              if (!res || res?.data === null) {
                console.error(
                  "Error deleting content :",
                  res?.message?.toString()
                );
                setIsModalOpen(false);
              } else {
                trackEvent({
                  action: auditActions.DELETE,
                  code,
                  type: auditTypes.ACTION,
                });
                setIsModalOpen(false);
                setOfferings(offerings.filter(item => item.code !== code));
                cleanStore({ code, documentType: entityType.OFFERING_TOPIC });
              }
            })
            .catch(e => {
              console.error("Error deleting content", e);
            });
          setIsModalOpen(false);
        }}
        isOpenProp={isModalOpen}
      />
      <LegendButton legend={unitsColorMap}/>
    </div>
  );
};

export default React.memo(Offering);
