import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTracking } from "react-tracking";
import { apis } from "services/config";
import {
  getQualification,
  getPPTTemplatesNoGraphic,
  getPptDownload,
  publish,
  saveAsDraft,
  update,
} from "services/quals";
import { getUserByEmail, getUser } from "services/user";
import { stripHtml } from "string-strip-html";
import {
  yearFilters,
  projectTypes,
  entityType,
  auditTypes,
  auditActions,
  inputLength,
  unitTypes,
} from "utils/commons";
import {
  downloadFile,
  checkField,
  checkFieldEditor,
  getOrganizationalUnitsIdsByNames,
  objectToArray,
} from "utils/utils";
import CustomSelect from "components/CustomSelect";
import TagsInput from "components/TagsInput";
import CloseIcon from "images/cancel.svg";
import DeleteIcon from "images/delete.png";
import CustomIndustrySelect from "../CustomIndustrySelect";
import CustomOUSelect from "../CustomOUSelect";
import RichTextEditor from "../RichTextEditor";
import Step from "./Step";

import "./style.scss";

const numberFields = ["year", "elapsed", "fte"];

const toggleFields = ["isCustomerPublishable"];

const multipleCustomSelectFields = [
  "countries",
  "epics",
  "tags",
  "projectTypes",
  "technologies",
  "businessChallengeCodes",
  "offeringTopicCodes",
  "secondaryOrganizationalUnitsIds",
];

const singleCustomSelectFields = [
  "elapsedUnit",
  "year",
  "customer",
  "subIndustryId",
  "industry",
  "wbsList",
];

const mandatoryMultipleFields = [
  "countries",
  "epics",
  "projectTypes",
  "personasIds",
];

const mandatorySingleFields = ["elapsedUnit", "customer", "subIndustryId"];

const QualModal = ({
  handleClose,
  show,
  code,
  profile,
  coe,
  personas,
  organizationalUnits,
  dictionaries,
  getDictionary,
  getOrganizationalUnits,
  businessChallenges,
  offerings,
  versionType,
  cleanStore,
  industries,
  industryTree,
  subindustries,
}) => {
  const { trackEvent } = useTracking();

  const [availableValues] = useState({
    "qual-related-offering": ["Offering 1", "Offering 2", "Offering 3"],
    elapsedUnit: ["months", "years", "weeks", "days"],
  });

  const [achievements, setAchievements] = useState([
    { value: "", description: "" },
  ]);
  const [selectedValues, setSelectedValues] = useState({});
  const [toggles, setToggles] = useState({ isCustomerPublishable: true });
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [references, setReferences] = useState({});
  const [pptTemplates, setPptTemplates] = useState([]);
  const [pptFile, setPptFile] = useState(null);
  const [cardTitle, setCardTitle] = useState("");
  const [selectedCoe, setSelectedCoe] = useState(null);
  const [loader, setLoader] = useState(false);
  const [searchMessage, setSearchMessage] = useState();
  const [arrBC, setArrayBC] = useState([]);
  const [arrOfferings, setArrayOfferings] = useState([]);
  const [searchReference, setSearchReference] = useState("");
  const [body, setBody] = useState("");
  const [isContactId, setIsContactId] = useState(false);
  const [subindustry, setSubindustry] = useState({});
  const [industryArray, setIndustryArray] = useState([]);

  useEffect(
    () => setIndustryArray(objectToArray(industryTree)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const ouTree = { COE: [], MFU_COE: [], MFU: [] };
  const [ouArray, setOuArray] = useState([]);

  useEffect(() => {
    organizationalUnits.length > 0
      ? organizationalUnits &&
        organizationalUnits?.map(
          ou =>
            ou?.type?.toUpperCase() !== unitTypes.SUBINDUSTRY &&
            ouTree[ou?.type?.toUpperCase()].push(ou)
        )
      : getOrganizationalUnits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => setOuArray(objectToArray(ouTree)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    setArrayBC(
      Object.keys(businessChallenges)?.map(k => ({
        id: k,
        value: businessChallenges[k]?.description,
      }))
    );
  }, [businessChallenges]);

  useEffect(() => {
      setArrayOfferings(
        offerings
          ?.filter(ot => {
              if(selectedCoe) {
                return selectedCoe.type === 'MFU' ? ot.organizationalUnitType === "SUBINDUSTRY" : ot.organizationalUnitId === selectedCoe.id
              }
              return true
            }
          )
          ?.map(v => ({
            id: v.code,
            value: `${v.code.split('-')[0]} - ${v.description}`,
          }))
      );
  }, [offerings, selectedCoe]);

  useEffect(() => {
    checkFieldEditor("body", body, 1020, setError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body]);

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    setError,
    reset,
    getValues,
    watch,
  } = useForm();

  const handleOption = (selectName, option) => {
    setSelectedValues({ ...selectedValues, [selectName]: option });
  };

  const handleRemoveOptionValue = (inputName, value = null) => {
    setSelectedValues({
      ...selectedValues,
      [inputName]: value
        ? selectedValues[inputName]?.filter(v => v !== value)
        : undefined,
    });
  };

  const handleToggle = toggleName => {
    setToggles({ ...toggles, [toggleName]: !toggles[toggleName] });
  };

  const handleAchievementInput = (index, property, value) => {
    if (
      (property === "value" && value.length <= inputLength.ACHIEVEMENT_TITLE) ||
      (property === "description" &&
        value.length <= inputLength.ACHIEVEMENT_DESCRIPTION)
    ) {
      setAchievements(
        achievements?.map((a, i) => {
          if (i === index) {
            a[property] = value;
          }
          return a;
        })
      );
    }
  };

  const removeAchievement = index => {
    setAchievements(achievements?.filter((a, i) => i !== index));
  };

  const handlePPTFileChange = event => {
    event?.persist();
    if (event?.target?.files[0]) {
      const reader = new FileReader();
      reader.onload = () => {
        setPptFile({
          name: event.target.files[0]?.name,
          data: reader.result?.toString().split("base64,")[1],
        });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleReferenceSearch = (event, referenceName) => {
    event?.preventDefault();
    setSearchMessage("");
    getUserByEmail(searchReference).then(res => {
      if (res?.data?.users?.length) {
        setReferences({ ...references, [referenceName]: res?.data?.users[0] });
        setIsContactId(true);
      } else {
        setIsContactId(false);
        setSearchMessage("No user found");
      }
    });
  };

  const handleTemplateGenerateButton = async () => {
    if (selectedTemplate) {
      const templateId = pptTemplates?.find(
        t => t?.name === selectedTemplate
      ).id;
      await handleSubmit(onSubmitDraft)()
      await downloadFile(
        `${apis.QUAL}/${code}/generated-slide-download?templateId=${templateId}`,
        `${code}.pptx`
      );
    }
  };

  const prepareDataSubmit = data => {
    data.isCustomerPublishable = data?.isCustomerPublishable === "true";
    Object.keys(data).forEach(k => {
      if (
        (!data[k] && k !== "isCustomerPublishable") ||
        k.startsWith("achievement-")
      ) {
        delete data[k];
      } else {
        if (numberFields.includes(k)) {
          data[k] = parseFloat(data[k]);
        }
      }
      if (
        k === "secondaryOrganizationalUnitsIds" &&
        selectedValues[k] &&
        selectedValues[k].length > 0
      ) {
        data[k] = getOrganizationalUnitsIdsByNames(
          organizationalUnits,
          selectedValues[k]
        );
      }
    });

    data.body = body;
    data.subIndustryId = subindustry?.id;
    data.achievements = achievements;
    if (references.tech) {
      data.referenceContactIds = [references.tech.id];
      setIsContactId(true);
    }
    return data;
  };

  const onSubmitDraft = data => {
    return new Promise((resolve) => {
      data = prepareDataSubmit(data);
      if (code) {
        setLoader(true);
        data.code = code;
        data.progressive = parseInt(data.progressive);
        update(code, data)
          .then(response => {
            if (response?.data) {
              trackEvent({
                code,
                action: auditActions.EDIT_SAVE_DRAFT,
                resourceType: entityType.QUALIFICATION,
                type: auditTypes.ACTION,
              });
              setLoader(false);
              resolve(true)
            } else {
              console.log(
                "Warning: Error submitting request " +
                  `${response?.message?.toString()}`
              );
              setLoader(false);
              resolve(false)
            }
          })
          .catch(error => {
            console.error(`QualModal error: ${error}`);
            setLoader(false);
            resolve(false)
          });
      } else {
        saveAsDraft(data)
          .then(response => {
            if (response?.data) {
              trackEvent({
                code: selectedCoe.code + "-" + data?.subUnitChar + "xxx",
                action: auditActions.NEW_SAVE_DRAFT,
                resourceType: entityType.QUALIFICATION,
                type: auditTypes.ACTION,
              });
              resolve(true)
            } else {
              console.log(
                "Warning: Error uploading content" +
                  " " +
                  response?.message?.toString()
              );
            }
            setLoader(false);
            resolve(false)
          })
          .catch(error => {
            console.error(`QualModal error: ${error}`);
            setLoader(false);
            resolve(false)
          });
      }
    })
  };

  const onSubmitPublish = data => {
    data = prepareDataSubmit(data);
    if (code && isContactId) {
      setLoader(true);
      data.code = code;
      data.progressive = parseInt(data?.progressive);
      update(code, data)
        .then(res => {
          if (res?.data) {
            publish(code).then(response => {
              if (response?.data) {
                trackEvent({
                  code,
                  action: auditActions.EDIT_AND_PUBLISH,
                  resourceType: entityType.QUALIFICATION,
                  type: auditTypes.ACTION,
                });
                cleanStore({ code, documentType: entityType.QUALIFICATION });
                handleClose();
              } else {
                console.log(
                  "Warning1: Error uploading content" +
                    " " +
                    response?.message?.toString()
                );
              }
              setLoader(false);
            });
          } else {
            console.error(
              `Warning! Error uploading content: ${res?.message?.toString()}`
            );
            setLoader(false);
          }
        })
        .catch(error => {
          console.log(`QualModal error: ${error}`);
          setLoader(false);
        });
    }
  };

  const sectionHeaders = {
    header: useRef(null),
    client: useRef(null),
    bip_categorization: useRef(null),
    details: useRef(null),
    other: useRef(null),
  };

  const [sectionStates, setSectionStates] = useState({
    header: "active",
    client: null,
    bip_categorization: null,
    details: null,
    other: null,
  });

  useEffect(() => {
    let isMounted = true;
    if (show && code) {
      reset();
      getQualification(
        code,
        (versionType || "").toUpperCase() === "DRAFT"
      ).then(res => {
        if (res) {
          const data = res?.data;
          let locSelectedValues = selectedValues;
          let locToggles = toggles;
          let locValues = {};
          let locReferences = {};
          let locReferenceEmails = {};
          data &&
            Object.keys(data).forEach(k => {
              if (multipleCustomSelectFields.includes(k)) {
                if (k === "secondaryOrganizationalUnitsIds") {
                  locSelectedValues[k] = organizationalUnits
                    ?.filter(p => data[k].includes(p.id))
                    ?.map(p => p.name);
                } else {
                  locSelectedValues[k] = data[k];
                }
              } else if (singleCustomSelectFields.includes(k)) {
                locSelectedValues[k] = data[k];
                if (k === "wbsList" && data[k]) {
                  locValues[k] = data[k].join(", ");
                }
              } else if (k === "personasIds") {
                locSelectedValues[k] = personas
                  ?.filter(p => data[k].includes(p.id))
                  ?.map(p => p.name);
              } else if (toggleFields.includes(k)) {
                locToggles[k] = data[k];
              } else if (k === "achievements") {
                setAchievements(data[k]);
              } else if (k === "referenceContactIds" && data[k]?.length) {
                getUser(data[k][0]).then(res => {
                  const user = res?.data;
                  locReferenceEmails.tech = user.email;
                  locReferences.tech = user;
                  if (!isMounted) return;
                  setIsContactId(true);
                });
              } else {
                locValues[k] = data[k];
              }
            });
          if (!isMounted) return;
          setSubindustry({
            ...subindustries?.find(
              sub => sub.id === selectedValues["subIndustryId"]
            ),
            industry: industries?.find(
              ind =>
                ind.id ===
                subindustries?.find(
                  sub => sub.id === selectedValues["subIndustryId"]
                )?.industryId
            )?.name,
          });
          if (data?.body && isMounted) {
            setBody(data.body);
          }
          if (!isMounted) return;
          setToggles(locToggles);
          locSelectedValues.offeringTopicCodes = res?.data?.offeringTopicCodes;
          setSelectedValues(locSelectedValues);
          const organizationalUnit = organizationalUnits?.find(
            x => x.id === data?.organizationalUnitId
          );
          setSelectedCoe(organizationalUnit);
          if (
            organizationalUnit?.spocs &&
            organizationalUnit.spocs?.length > 0 &&
            organizationalUnit.spocs[0]?.userId
          ) {
            getUser(organizationalUnit.spocs[0].userId).then(res => {
              const user = res?.data;
              locReferenceEmails.main = user.email;
              locReferences.main = user;
            });
          }
          getPptDownload(
            code,
            (versionType || "").toUpperCase() === "DRAFT"
          ).then(base64 => {
            if (base64 && isMounted) {
              setPptFile({ data: base64 });
            }
          });
          if (data?.title && isMounted) setCardTitle(data?.title);
          setReferences(locReferences);
          Object.keys(locValues).forEach(k => {
            setValue(k, locValues[k]);
          });
        }
      });
    } else if (show && !code && isMounted) {
      setSelectedValues({});
      setToggles({});
      setAchievements([]);
      setReferences({});
      reset();
      const organizationalUnit = coe ? coe : profile?.organizationalUnit;
      if (!isMounted) return;
      setSelectedCoe(organizationalUnit);
      if (
        organizationalUnit.spocs &&
        organizationalUnit.spocs.length > 0 &&
        organizationalUnit.spocs[0]?.userId &&
        isMounted
      ) {
        getUser(organizationalUnit.spocs[0].userId).then(res => {
          const user = res?.data;

          setReferences({ ...references, main: user });
        });
      }
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, show]);

  useEffect(() => {
    let isMounted = true;

    getPPTTemplatesNoGraphic().then(res => setPptTemplates(res?.data));
    getDictionary({ type: "COUNTRY" });
    getDictionary({ type: "EPIC" });
    getDictionary({ type: "TECHNOLOGY" });
    getDictionary({ type: "CUSTOMER" });

    const interval = setInterval(() => {
      if (show) {
        let states = {};
        let foundActive = false;
        let sectionNames = Object.keys(sectionHeaders);
        for (let i = sectionNames.length - 1; i >= 0; i--) {
          let pos =
            sectionHeaders[sectionNames[i]].current?.getBoundingClientRect().y;
          if (pos < window.innerHeight / 2 || i === 0) {
            if (!foundActive) {
              states[sectionNames[i]] = "active";
              foundActive = true;
            } else states[sectionNames[i]] = "completed";
          } else states[sectionNames[i]] = null;
        }
        if (!isMounted) return;
        setSectionStates({ ...sectionStates, ...states });
      }
    }, 300);

    return () => {
      isMounted = false;
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const [canSubmitDraft, setCanSubmitDraft] = useState(false);
  const [canPublish, setCanPublish] = useState(false);

  const watchSubUnitChar = watch("subUnitChar");
  const watchOrganizationalUnitId = watch("organizationalUnitId");
  const watchTitle = watch("title");
  const watchCategory = watch("category");
  const watchHeadline = watch("headline");
  const watchCustomerAlias = watch("customerAlias");
  const watchElapsed = watch("elapsed");
  const watchFte = watch("fte");
  const watchPPT = watch("ppt");

  useEffect(() => {
    setCanSubmitDraft(
      !!getValues("subUnitChar") &&
        !!getValues("organizationalUnitId") &&
        !!getValues("title")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchSubUnitChar, watchOrganizationalUnitId, watchTitle]);

  useEffect(() => {
    let publishable = true;
    mandatoryMultipleFields.forEach(f => {
      publishable = publishable && selectedValues[f]?.length > 0;
    });
    mandatorySingleFields.forEach(f => {
      publishable = publishable && !!selectedValues[f];
    });
    publishable =
      publishable &&
      !!getValues("subUnitChar") &&
      !!getValues("organizationalUnitId") &&
      !!getValues("title") &&
      !!getValues("category") &&
      !!getValues("headline") &&
      (toggles.isCustomerPublishable || !!getValues("customerAlias")) &&
      !!getValues("elapsed") &&
      !!getValues("fte") &&
      !!getValues("ppt") &&
      Object.keys(references).length > 0;
    setCanPublish(publishable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    watchSubUnitChar,
    watchOrganizationalUnitId,
    watchTitle,
    watchCategory,
    watchHeadline,
    watchCustomerAlias,
    watchElapsed,
    watchFte,
    watchPPT,
    selectedValues,
    toggles,
    references,
  ]);

  return (
    show && (
      <div className="modal">
        <section className="modal-main">
          <div className="bo-header">
            <h1 className="bo-title">{code ? "Editing of" : "Creating of"}</h1>
            <h1 className="bo-title-value">{code ? cardTitle : "New Qual"}</h1>
            <div className="bo-header-close" onClick={handleClose}>
              <img src={CloseIcon} alt="" />
            </div>
          </div>
          <div className="bo-container">
            <div className="legend">
              <div>
                <label>
                  <span>*</span>mandatory for saving
                </label>
              </div>
              <div>
                <label>
                  <span>**</span> mandatory for publishing
                </label>
              </div>
            </div>
            <Step sectionStates={sectionStates} type="qual" />

            <form className="bo-form-container">
              <div
                className="bo-form-section-title"
                ref={sectionHeaders.header}>
                1. HEADER
              </div>
              <div className="bo-form-section">
                <div className="bo-form-subtitle">Qual unique code</div>
                <div className="bo-form-input-group">
                  <div className="bo-form-input-container">
                    <label>
                      owner unit<span>*</span>
                    </label>
                    <input
                      name="competence-coe"
                      type="text"
                      value={selectedCoe?.name || ""}
                      readOnly
                    />
                    <input
                      name="organizationalUnitId"
                      type="hidden"
                      value={selectedCoe?.id || ""}
                      ref={register}
                    />
                  </div>
                  <div className="bo-form-input-container">
                    <label>Contributor units</label>
                    <CustomOUSelect
                      label="Select one or more"
                      value={
                        selectedValues["secondaryOrganizationalUnitsIds"] || []
                      }
                      items={
                        ouArray &&
                        ouArray?.reduce((acc, el) => {
                          if (el?.id !== selectedCoe?.id) {
                            if (el.id) {
                              acc.push({
                                type: "option",
                                id: el.id,
                                value: el.name,
                                code: el.code,
                              });
                            } else {
                              acc.push({
                                type: "title",
                                id: el,
                                value: el,
                                code: "",
                              });
                            }
                          }
                          return acc;
                        }, [])
                      }
                      onChange={val =>
                        handleOption("secondaryOrganizationalUnitsIds", val)
                      }
                      isMultiple
                      isAutocomplete
                    />
                    <input
                      type="hidden"
                      name="secondaryOrganizationalUnitsIds"
                      value={
                        selectedValues["secondaryOrganizationalUnitsIds"] || []
                      }
                      ref={register}
                    />
                    {selectedValues["secondaryOrganizationalUnitsIds"] && (
                      <div className="bo-form-chip-list">
                        {selectedValues["secondaryOrganizationalUnitsIds"]?.map(
                          (v, i) => (
                            <div key={i} className="bo-form-chip">
                              {v}
                              <img
                                className="chip-icon"
                                src={CloseIcon}
                                alt=""
                                onClick={() =>
                                  handleRemoveOptionValue(
                                    "secondaryOrganizationalUnitsIds",
                                    v
                                  )
                                }
                              />
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                  <div className="bo-form-input-container">
                    <label>
                      letter to point out competence<span>*</span>
                    </label>
                    <input
                      name="subUnitChar"
                      type="text"
                      onChange={event =>
                        checkField("subUnitChar", event, 1, setError)
                      }
                      ref={register({ required: true, maxLength: 50 })}
                      placeholder="Write letter here..."
                    />
                    {errors.subUnitChar && errors.subUnitChar.message && (
                      <p>{errors.subUnitChar.message}</p>
                    )}
                  </div>
                  {code && (
                    <div className="bo-form-input-container">
                      <label>
                        progressive number<span>*</span>
                      </label>
                      <input
                        name="progressive"
                        type="number"
                        ref={register()}
                        readOnly
                      />
                      <div className="bo-form-code">
                        <div className="bo-form-code-label">
                          Unique code generated:
                        </div>
                        <div className="bo-form-code-value">{code}</div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="bo-form-input-group">
                  <div className="bo-form-input-container">
                    <label>
                      qual category<span>**</span>
                    </label>
                    <input
                      name="category"
                      ref={register}
                      placeholder="Write only one category"
                    />
                    {errors.category && <p>This is required</p>}
                  </div>
                </div>
                <div className="bo-form-input-group">
                  <div className="bo-form-input-container">
                    <label>
                      qual title<span>*</span>
                      <span className="bo-input-counter">
                        {getValues("title")?.length || 0}/100
                      </span>
                    </label>
                    <input
                      name="title"
                      type="text"
                      placeholder="Write qual title here"
                      maxLength={100}
                      onChange={event =>
                        checkField("title", event, 100, setError)
                      }
                      onFocus={event =>
                        checkField("title", event, 100, setError)
                      }
                      ref={register({ required: true, maxLength: 100 })}
                    />
                    {errors.title && errors.title.message && (
                      <p>{errors.title.message}</p>
                    )}
                  </div>
                  <div className="bo-form-input-container">
                    <label>
                      qual headline <span>**</span>
                      <span className="bo-input-counter">
                        {getValues("headline")?.length || 0}/80
                      </span>
                    </label>
                    <input
                      name="headline"
                      type="text"
                      placeholder="Write qual slogan here"
                      maxLength={80}
                      onChange={event =>
                        checkField("headline", event, 80, setError, false)
                      }
                      onFocus={event =>
                        checkField("headline", event, 80, setError, false)
                      }
                      ref={register({ maxLength: 80 })}
                    />
                    {errors.headline && errors.headline.message && (
                      <p>{errors.headline.message}</p>
                    )}
                  </div>
                  <div className="bo-form-input-container">
                    <label>qual keywords</label>
                    <TagsInput
                      label="Hit enter to add each keyword"
                      value={selectedValues["tags"] || []}
                      onChange={val => handleOption("tags", val)}
                      icon={"add-icon"}
                      id="input-with-icon__input-qk"
                    />
                    {selectedValues["tags"] &&
                      selectedValues["tags"]?.map((v, i) => (
                        <input
                          key={i}
                          type="hidden"
                          name={"tags." + i}
                          ref={register}
                          value={v}
                        />
                      ))}
                    {selectedValues["tags"] && (
                      <div className="bo-form-chip-list">
                        {selectedValues["tags"]?.map((v, i) => (
                          <div key={i} className="bo-form-chip">
                            {v}
                            <img
                              className="chip-icon"
                              src={CloseIcon}
                              alt=""
                              onClick={() => handleRemoveOptionValue("tags", v)}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="bo-form-input-group">
                  <div className="bo-form-input-container">
                    <label className="bo-large-label">
                      qual body<span>**</span>
                      <span className="bo-input-counter">
                        {body
                          ? stripHtml(body.replace("&nbsp;", " ")).result.length
                          : 0}
                        /1020
                      </span>
                    </label>
                    <div className="CKEditor-container">
                      <RichTextEditor
                        placeholder="Write qual body here"
                        data={body}
                        callback={setBody}
                      />
                    </div>
                    <textarea
                      name="body"
                      className="no-textarea"
                      rows={10}
                      //maxLength={1020}
                      value={body || ""}
                      onChange={() =>
                        checkFieldEditor("body", body, 1020, setError, false)
                      }
                      //onFocus={event => checkFieldEditor("body", body, 1020, setError, false)}
                      ref={register}
                    />
                    {errors.body && errors.body.message && (
                      <p>{errors.body.message}</p>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="bo-form-section-title"
                ref={sectionHeaders.client}>
                2. CLIENT
              </div>
              <div className="bo-form-section">
                <div className="bo-form-input-group">
                  <div className="bo-form-input-container">
                    <label>
                      client industry<span>**</span>
                    </label>
                    <CustomIndustrySelect
                      label="Write only one industry"
                      value={selectedValues["subIndustryId"] || []}
                      items={industryArray?.map(v => {
                        if (v.id) {
                          return {
                            type: "option",
                            id: v.id,
                            value: v.name,
                            code: v.code,
                          };
                        }
                        return {
                          type: "title",
                          id: v,
                          value: industries?.find(i => i.id === v).name,
                          code: industries?.find(i => i.id === v).code,
                        };
                      })}
                      onChange={(code, id) => {
                        setSubindustry({
                          ...subindustries?.find(sub => sub.id === id),
                          industry: industries?.find(
                            ind =>
                              ind.id ===
                              subindustries?.find(sub => sub.id === id)
                                ?.industryId
                          ).name,
                        });
                        return handleOption("subIndustryId", id);
                      }}
                      closeOnSelect
                    />
                    <input
                      type="hidden"
                      name="subIndustryId"
                      value={selectedValues["subIndustryId"] || ""}
                      ref={register}
                    />
                    <div className="bo-form-chip-list">
                      {selectedValues["subIndustryId"] && (
                        <div className="bo-form-chip">
                          {subindustry?.industry}
                          {" - "}
                          {subindustry?.name}{" "}
                          <img
                            className="chip-icon"
                            src={CloseIcon}
                            alt=""
                            onClick={() => {
                              setSubindustry(null);
                              handleRemoveOptionValue("subIndustryId");
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="bo-form-input-container">
                    <label>
                      client name<span>**</span>
                    </label>
                    <CustomSelect
                      label="Write client name here..."
                      value={selectedValues["customer"] || []}
                      items={
                        dictionaries?.CUSTOMER &&
                        dictionaries?.CUSTOMER?.map(i => ({
                          id: i.id,
                          value: i.name,
                        }))
                      }
                      onChange={val => handleOption("customer", val)}
                      isAutocomplete
                      closeOnSelect
                    />
                    <input
                      type="hidden"
                      name="customer"
                      value={selectedValues["customer"] || ""}
                      ref={register}
                    />
                    <div className="bo-form-chip-list">
                      {selectedValues["customer"] && (
                        <div className="bo-form-chip">
                          {selectedValues["customer"]}
                          <img
                            className="chip-icon"
                            src={CloseIcon}
                            alt=""
                            onClick={() => handleRemoveOptionValue("customer")}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bo-form-input-group">
                  <div className="bo-form-input-container">
                    <label>is client name publishable?</label>
                    <div
                      className="bo-form-toggle-input"
                      onClick={() => handleToggle("isCustomerPublishable")}>
                      <div
                        className={
                          toggles["isCustomerPublishable"]
                            ? "toggle active"
                            : "toggle"
                        }>
                        <div className="handle" />
                      </div>
                      <div className="text-value">
                        {toggles["isCustomerPublishable"] ? "YES" : "NO"}
                      </div>
                      <input
                        type="hidden"
                        name="isCustomerPublishable"
                        value={
                          toggles["isCustomerPublishable"]?.toString() || ""
                        }
                        ref={register}
                      />
                    </div>
                  </div>
                </div>
                <div className="bo-form-input-group">
                  {!toggles["isCustomerPublishable"] && (
                    <div className="bo-form-input-container">
                      <label>
                        client hidden name<span>**</span>
                      </label>
                      <input
                        name="customerAlias"
                        placeholder="Write client hidden name here..."
                        ref={register}
                      />
                    </div>
                  )}
                  <div className="bo-form-input-container">
                    <label>
                      client personas<span>**</span>
                    </label>
                    <CustomSelect
                      label=""
                      value={selectedValues["personasIds"] || []}
                      items={personas?.map(o => ({ id: o.id, value: o.name }))}
                      onChange={val => handleOption("personasIds", val)}
                      isMultiple
                      isAutocomplete
                    />
                    {selectedValues["personasIds"] &&
                      selectedValues["personasIds"]?.map((v, i) => (
                        <input
                          key={i}
                          type="hidden"
                          name={"personasIds." + i}
                          ref={register}
                          value={personas?.find(p => p.name === v)?.id}
                        />
                      ))}
                    {selectedValues["personasIds"] && (
                      <div className="bo-form-chip-list">
                        {selectedValues["personasIds"]?.map((v, i) => (
                          <div key={i} className="bo-form-chip">
                            {v}
                            <img
                              className="chip-icon"
                              src={CloseIcon}
                              alt=""
                              onClick={() =>
                                handleRemoveOptionValue("personasIds", v)
                              }
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="bo-form-input-container">
                    <label>
                      qual country<span>**</span>
                    </label>
                    <CustomSelect
                      label=""
                      value={selectedValues["countries"] || []}
                      items={
                        dictionaries?.COUNTRY &&
                        dictionaries?.COUNTRY?.map(o => ({
                          id: o.id,
                          value: o.name,
                        }))
                      }
                      onChange={val => handleOption("countries", val)}
                      isMultiple
                      isAutocomplete
                    />
                    {selectedValues["countries"] &&
                      selectedValues["countries"]?.map((v, i) => (
                        <input
                          key={i}
                          type="hidden"
                          name={"countries." + i}
                          ref={register}
                          value={v}
                        />
                      ))}
                    {selectedValues["countries"] && (
                      <div className="bo-form-chip-list">
                        {selectedValues["countries"]?.map((v, i) => (
                          <div key={i} className="bo-form-chip">
                            {v}
                            <img
                              className="chip-icon"
                              src={CloseIcon}
                              alt=""
                              onClick={() =>
                                handleRemoveOptionValue("countries", v)
                              }
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="bo-form-section-title"
                ref={sectionHeaders.bip_categorization}>
                3. BIP CATEGORIZATION
              </div>
              <div className="bo-form-section">
                <div className="bo-form-input-group">
                  <div className="bo-form-input-container">
                    <label>
                      qual project type<span>**</span>
                    </label>
                    <CustomSelect
                      label="Select project type"
                      value={selectedValues["projectTypes"] || []}
                      items={projectTypes}
                      onChange={val => handleOption("projectTypes", val)}
                      isMultiple
                    />
                    {selectedValues["projectTypes"] &&
                      selectedValues["projectTypes"]?.map((v, i) => (
                        <input
                          key={i}
                          type="hidden"
                          name={"projectTypes." + i}
                          ref={register}
                          value={v}
                        />
                      ))}
                    {selectedValues["projectTypes"] && (
                      <div className="bo-form-chip-list">
                        {selectedValues["projectTypes"]?.map((v, i) => (
                          <div key={i} className="bo-form-chip">
                            {v}
                            <img
                              className="chip-icon"
                              src={CloseIcon}
                              alt=""
                              onClick={() =>
                                handleRemoveOptionValue("projectTypes", v)
                              }
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="bo-form-input-group">
                  <div className="bo-form-input-container">
                    <label>
                      qual epics<span>**</span>
                    </label>
                    <CustomSelect
                      label="Select epics"
                      value={selectedValues["epics"] || []}
                      items={
                        dictionaries?.EPIC &&
                        dictionaries?.EPIC?.map(o => ({
                          id: o.id,
                          value: o.name,
                        }))
                      }
                      onChange={val => handleOption("epics", val)}
                      isMultiple
                      isAutocomplete
                    />
                    {selectedValues["epics"] &&
                      selectedValues["epics"]?.map((v, i) => (
                        <input
                          key={i}
                          type="hidden"
                          name={"epics." + i}
                          ref={register}
                          value={v}
                        />
                      ))}
                    {selectedValues["epics"] && (
                      <div className="bo-form-chip-list">
                        {selectedValues["epics"]?.map((v, i) => (
                          <div key={i} className="bo-form-chip">
                            {v}
                            <img
                              className="chip-icon"
                              src={CloseIcon}
                              alt=""
                              onClick={() =>
                                handleRemoveOptionValue("epics", v)
                              }
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="bo-form-input-group">
                  <div className="bo-form-input-container">
                    <label>qual business challenges</label>
                    <CustomSelect
                      label="Select business challenges"
                      value={
                        selectedValues["businessChallengeCodes"]
                          ? arrBC
                              ?.filter(
                                bc =>
                                  selectedValues[
                                    "businessChallengeCodes"
                                  ]?.includes(bc.id) ||
                                  selectedValues[
                                    "businessChallengeCodes"
                                  ]?.includes(bc.value)
                              )
                              ?.map(bc => bc.value)
                          : []
                      }
                      items={arrBC}
                      onChange={val =>
                        handleOption("businessChallengeCodes", val)
                      }
                      isMultiple
                      isAutocomplete
                    />
                    {selectedValues["businessChallengeCodes"] &&
                      selectedValues["businessChallengeCodes"]?.map((v, i) => (
                        <input
                          key={i}
                          type="hidden"
                          name={"businessChallengeCodes." + i}
                          ref={register}
                          value={
                            arrBC?.find(bc => bc.id === v || bc.value === v)?.id
                          }
                        />
                      ))}
                    {selectedValues["businessChallengeCodes"] && (
                      <div className="bo-form-chip-list">
                        {selectedValues["businessChallengeCodes"]?.map(
                          (v, i) => (
                            <div key={i} className="bo-form-chip">
                              {arrBC
                                .find(bc => bc.id === v || bc.value === v)
                                ?.value.slice(0, 40) + "..."}
                              <img
                                className="chip-icon"
                                src={CloseIcon}
                                alt=""
                                onClick={() =>
                                  handleRemoveOptionValue(
                                    "businessChallengeCodes",
                                    v
                                  )
                                }
                              />
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="bo-form-input-group">
                  <div className="bo-form-input-container">
                    <label>qual related offerings</label>
                    <CustomSelect
                      label="Select qual related offerings"
                      value={
                        selectedValues["offeringTopicCodes"]
                          ? arrOfferings
                              ?.filter(
                                ot =>
                                  selectedValues[
                                    "offeringTopicCodes"
                                  ]?.includes(ot.id) ||
                                  selectedValues[
                                    "offeringTopicCodes"
                                  ]?.includes(ot.value)
                              )
                              ?.map(ot => ot.value)
                          : []
                      }
                      items={arrOfferings}
                      onChange={val => handleOption("offeringTopicCodes", val)}
                      isMultiple
                      isAutocomplete
                    />
                    {selectedValues["offeringTopicCodes"] &&
                      selectedValues["offeringTopicCodes"]?.map((v, i) => (
                        <input
                          key={i}
                          type="hidden"
                          name={"offeringTopicCodes." + i}
                          ref={register}
                          value={
                            arrOfferings?.find(
                              ot => ot.id === v || ot.value === v
                            )?.id
                          }
                        />
                      ))}
                    {selectedValues["offeringTopicCodes"] && (
                      <div className="bo-form-chip-list">
                        {selectedValues["offeringTopicCodes"]?.map((v, i) => {
                          const el = arrOfferings?.find(
                            ot => ot.id === v || ot.value === v
                          )?.value
                          if (el)
                            return (
                              <div key={i} className="bo-form-chip">
                                {
                                  el
                                }
                                <img
                                  className="chip-icon"
                                  src={CloseIcon}
                                  alt=""
                                  onClick={() =>
                                    handleRemoveOptionValue("offeringTopicCodes", v)
                                  }
                                />
                              </div>
                        )})}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="bo-form-section-title"
                ref={sectionHeaders.details}>
                4. DETAILS
              </div>
              <div className="bo-form-section">
                <div className="bo-form-input-group">
                  <div className="bo-form-input-container">
                    <label>project start year</label>
                    <CustomSelect
                      label="Select project start year"
                      value={selectedValues["year"] || []}
                      items={yearFilters?.map(y => ({ value: y }))}
                      onChange={val => handleOption("year", val)}
                      closeOnSelect
                    />
                    <input
                      type="hidden"
                      name="year"
                      value={selectedValues["year"] || ""}
                      ref={register}
                    />
                    <div className="bo-form-chip-list">
                      {selectedValues["year"] ? (
                        <div className="bo-form-chip">
                          {selectedValues["year"]}
                          <img
                            className="chip-icon"
                            src={CloseIcon}
                            alt=""
                            onClick={() => handleRemoveOptionValue("year")}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="bo-form-input-container">
                    <label>
                      qual elapsed (number and unit)<span>**</span>
                    </label>
                    <div className="bo-form-input-line">
                      <input
                        type="number"
                        placeholder="00.00"
                        className="bo-form-short-input"
                        name="elapsed"
                        ref={register}
                      />
                      <CustomSelect
                        label="Select time unit"
                        value={selectedValues["elapsedUnit"] || []}
                        items={availableValues["elapsedUnit"]?.map((v, i) => ({
                          id: i,
                          value: v,
                        }))}
                        onChange={val => handleOption("elapsedUnit", val)}
                        closeOnSelect
                      />
                      <input
                        type="hidden"
                        name="elapsedUnit"
                        value={selectedValues["elapsedUnit"] || ""}
                        ref={register}
                      />
                    </div>
                    <div className="bo-form-chip-list">
                      {selectedValues["elapsedUnit"] && (
                        <div className="bo-form-chip">
                          {selectedValues["elapsedUnit"]}
                          <img
                            className="chip-icon"
                            src={CloseIcon}
                            alt=""
                            onClick={() =>
                              handleRemoveOptionValue("elapsedUnit")
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="bo-form-input-container">
                    <label>
                      qual fte<span>**</span>
                    </label>
                    <input
                      type="number"
                      placeholder="00.00"
                      className="bo-form-short-input"
                      name="fte"
                      ref={register}
                    />
                  </div>
                  <div className="bo-form-input-container">
                    <label>qual technologies</label>
                    <CustomSelect
                      label="Search for technologies"
                      value={selectedValues["technologies"] || []}
                      items={
                        dictionaries?.TECHNOLOGY &&
                        dictionaries?.TECHNOLOGY?.map(o => ({
                          id: o.id,
                          value: o.name,
                        }))
                      }
                      onChange={val => handleOption("technologies", val)}
                      isMultiple
                      isAutocomplete
                    />
                    {selectedValues["technologies"] &&
                      selectedValues["technologies"]?.map((v, i) => (
                        <input
                          key={i}
                          type="hidden"
                          name={"technologies." + i}
                          ref={register}
                          value={v}
                        />
                      ))}
                    {selectedValues["technologies"] && (
                      <div className="bo-form-chip-list">
                        {selectedValues["technologies"]?.map((v, i) => (
                          <div key={i} className="bo-form-chip">
                            {v}
                            <img
                              className="chip-icon"
                              src={CloseIcon}
                              alt=""
                              onClick={() =>
                                handleRemoveOptionValue("technologies", v)
                              }
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="bo-form-input-container">
                    <label>WBS list</label>
                    <TagsInput
                      label="Insert project WBS "
                      value={selectedValues["wbsList"] || []}
                      onChange={val => handleOption("wbsList", val)}
                      icon={"add-icon"}
                      id="input-with-icon__input-wbs"
                    />
                    {selectedValues["wbsList"] &&
                      selectedValues["wbsList"]?.map((v, i) => (
                        <input
                          key={i}
                          type="hidden"
                          name={"wbsList." + i}
                          ref={register}
                          value={v}
                        />
                      ))}
                    {selectedValues["wbsList"] && (
                      <div className="bo-form-chip-list">
                        {selectedValues["wbsList"]?.map((v, i) => (
                          <div key={i} className="bo-form-chip">
                            {v}
                            <img
                              className="chip-icon"
                              src={CloseIcon}
                              alt=""
                              onClick={() =>
                                handleRemoveOptionValue("wbsList", v)
                              }
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="bo-form-subtitle">Project achievement</div>
                {achievements?.map((a, i) => (
                  <div key={i} className="bo-form-input-group">
                    {i > 0 && <div className="bo-form-divider" />}
                    {i > 0 && (
                      <div className="bo-achievement-delete-container">
                        <img
                          onClick={() => removeAchievement(i)}
                          className="delete-icon"
                          src={DeleteIcon}
                          alt=""
                        />
                      </div>
                    )}
                    <div className="bo-form-input-container">
                      <label>
                        {"achievement " + (i + 1) + " (title)"}
                        <span className="bo-input-counter">
                          {getValues("achievement-title." + i)?.length || 0}/
                          {inputLength.ACHIEVEMENT_TITLE}
                        </span>
                      </label>
                      <input
                        placeholder={`Write achevement title max ${inputLength.ACHIEVEMENT_TITLE} characters`}
                        name={"achievement-title." + i}
                        value={a.value}
                        ref={register}
                        onChange={event =>
                          handleAchievementInput(i, "value", event.target.value)
                        }
                      />
                    </div>
                    <div className="bo-form-input-container">
                      <label>
                        {"achievement " + (i + 1) + " (body)"}
                        <span className="bo-input-counter">
                          {getValues("achievement-body." + i)?.length || 0}/
                          {inputLength.ACHIEVEMENT_DESCRIPTION}
                        </span>
                      </label>
                      <textarea
                        rows={5}
                        placeholder={`Write achevement body max ${inputLength.ACHIEVEMENT_DESCRIPTION} characters`}
                        name={"achievement-body." + i}
                        className="bo-form-short-textarea"
                        value={a.description}
                        ref={register}
                        onChange={event =>
                          handleAchievementInput(
                            i,
                            "description",
                            event.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                ))}
                <div
                  className="bo-button"
                  onClick={() =>
                    setAchievements([
                      ...achievements,
                      { value: "", description: "" },
                    ])
                  }>
                  add achievement
                </div>
              </div>
              <div className="bo-form-section-title" ref={sectionHeaders.other}>
                5. OTHER
              </div>
              <div className="bo-form-section">
                <div className="bo-form-input-group">
                  <div className="bo-form-subtitle">Main reference person</div>
                  <div className="bo-form-input-container">
                    <label>name</label>
                    <input
                      type="text"
                      value={references?.main?.name || ""}
                      readOnly
                      placeholder="Name of qual Main reference"
                    />
                  </div>
                  <div className="bo-form-input-container">
                    <label>surname</label>
                    <input
                      type="text"
                      value={references?.main?.surname || ""}
                      readOnly
                      placeholder="Surname of qual main reference"
                    />
                  </div>
                  <div className="bo-form-input-container">
                    <label>
                      e-mail<span>**</span>
                    </label>
                    <input
                      type="text"
                      readOnly
                      value={references?.main?.email || ""}
                      placeholder="E-mail of qual main reference"
                    />
                  </div>
                </div>
                <div className="bo-form-input-group">
                  <div className="bo-form-subtitle">Tech reference person</div>
                  <div className="bo-form-input-container">
                    <label>
                      e-mail<span>**</span>
                    </label>
                    <input
                      type="text"
                      onChange={event => setSearchReference(event.target.value)}
                      value={searchReference || ""}
                      placeholder="E-mail of qual technical reference"
                    />

                    <button
                      className="bo-button"
                      onClick={event => handleReferenceSearch(event, "tech")}>
                      FIND
                    </button>
                    {searchMessage && (
                      <div className="error-msg">{searchMessage}</div>
                    )}
                  </div>
                  <div className="bo-form-input-container">
                    <label>name</label>
                    <input
                      type="text"
                      readOnly
                      value={references?.tech?.name || ""}
                      placeholder="Name of qual technical reference"
                    />
                  </div>
                  <div className="bo-form-input-container">
                    <label>surname</label>
                    <input
                      type="text"
                      readOnly
                      value={references?.tech?.surname || ""}
                      placeholder="Surname of qual technical reference"
                    />
                  </div>
                  <div className="bo-form-input-container">
                    <label>phone number</label>
                    <input
                      type="text"
                      readOnly
                      value={references?.tech?.phone || ""}
                      placeholder="Phone number of qual technical reference"
                    />
                  </div>
                  <div className="bo-form-input-container">
                    <label>microsoft teams account</label>
                    <input
                      type="text"
                      value={references?.tech?.email || ""}
                      readOnly
                      placeholder="Microsoft teams account"
                    />
                  </div>
                </div>

                <div className="bo-form-subtitle">Qual deck</div>

                {code && (
                  <div className="bo-form-input-group">
                    <div className="bo-form-input-container">
                      <label>slide template</label>
                      <CustomSelect
                        label={
                          selectedTemplate
                            ? selectedTemplate
                            : "Select slide template to be used"
                        }
                        value={selectedTemplate}
                        items={pptTemplates?.map(t => ({
                          id: t.id,
                          value: t.name,
                        }))}
                        onChange={val => setSelectedTemplate(val)}
                        closeOnSelect
                      />
                    </div>
                    <div className="bo-button-list">
                      <div
                        className="bo-button"
                        onClick={() => handleTemplateGenerateButton()}>
                        Generate
                      </div>
                    </div>
                    <div className="bo-form-divider" />
                    <div className="bo-text-hint">
                      Please download the slide with the button above, review
                      and adjust it on Microsoft Power Point, then upload it
                      below. Warning: slides are generated from the last set of
                      saved data. To update slides too, first save the current
                      changes and then request the slide generation again.
                    </div>
                  </div>
                )}
                <div className="bo-form-input-group">
                  <div className="bo-form-input-container">
                    <label>
                      slide upload<span>**</span>
                    </label>
                    <label htmlFor="pptInput" className="file-label">
                      {pptFile
                        ? "File: " +
                          (pptFile.name ? pptFile.name : "presentation.pptx")
                        : "Upload .pptx file here"}
                    </label>
                    <input
                      id="pptInput"
                      name="ppt"
                      type="file"
                      accept=".pptx"
                      onChange={handlePPTFileChange}
                    />
                    {pptFile && (
                      <input
                        type="hidden"
                        name="ppt"
                        value={pptFile.data}
                        ref={register}
                      />
                    )}
                    {!pptFile && (
                      <input type="hidden" name="ppt" ref={register} />
                    )}
                  </div>
                  <div className="bo-button-list">
                    <label htmlFor="pptInput" className="bo-button">
                      upload reviewed slide
                    </label>
                    {pptFile && code && (
                      <div
                        className="bo-button"
                        onClick={() =>
                          downloadFile(
                            `${apis.QUAL}/${code}/ppt`,
                            pptFile.name ? pptFile.name : "presentation.pptx",
                            (versionType || "").toUpperCase() === "DRAFT"
                          )
                        }>
                        DOWNLOAD SLIDE
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
            {!code && (
              <input
                type="hidden"
                name="versionType"
                value="DRAFT"
                ref={register}
              />
            )}
            <div className="bo-submit-container">
              {loader ? (
                <span style={{ color: "#000" }}>Saving Data..</span>
              ) : (
                <span>
                  <button
                    className="bo-link"
                    disabled={
                      !canSubmitDraft ||
                      (errors?.body && errors.body?.type === "maxLength") ||
                      loader
                    }
                    onClick={() => {
                      handleSubmit(onSubmitDraft)();
                    }}>
                    Save as a draft
                  </button>
                  <button
                    className="bo-button"
                    disabled={
                      !code ||
                      !canPublish ||
                      !isContactId ||
                      (errors.body && errors.body.type) ||
                      loader
                    }
                    onClick={() => {
                      handleSubmit(onSubmitPublish)();
                    }}>
                    Submit qual
                  </button>
                </span>
              )}
            </div>
          </div>
        </section>
      </div>
    )
  );
};

export default QualModal;
