import React from "react";
import "./style.scss";

const PageSize = ({
  pageSize,
  setPageSize,
}) => {

  return (
    <div className="filters-container">
      <div className="filter-bar">
        <div className="select-container">
          <div className="label">SHOW:&nbsp;</div>
          <select
            value={pageSize}
            className="sort-select"
            onChange={e =>setPageSize(e.currentTarget.value)}>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
          <div className="label">&nbsp; USERS</div>
        </div>
      </div>
    </div>
  );
};

export default PageSize;
