import { apis, serviceInterface } from "./config";

export const getOfferingTopic = code => {
  return serviceInterface.get(`${apis.OFFERING_TOPIC}/${code}`);
};
export const getOfferingTopicNoGraphic = code => {
  return serviceInterface.get(`${apis.OFFERING_TOPIC}/${code}?no_graphic=true`);
};

export const getRelated = id => {
  return serviceInterface.get(`${apis.OFFERING_TOPIC}/${id}/links`);
};
export const getOfferingTopicByOrganizationalUnitId = id => {
  return serviceInterface.get(
    `${apis.OFFERING_TOPIC}?organizationalUnitId=${id}`
  );
};
export const save = params => {
  return serviceInterface.post(apis.OFFERING_TOPIC, { ...params });
};
export const update = (code, params) => {
  return serviceInterface.put(`${apis.OFFERING_TOPIC}/${code}`, { ...params });
};
export const deleteOffering = code => {
  return serviceInterface.delete(`${apis.OFFERING_TOPIC}/${code}`);
};

export const getAllOfferingTopics = () => {
  return serviceInterface.get(`${apis.OFFERING_TOPIC_LIGHT}`);
};
