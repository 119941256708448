import React, { useEffect, useState } from "react";
import { searchOfferingTopic } from "services/serp";
import { entityType, filtersMyracle } from "utils/commons";
import SearchView from "components/SearchView";
import useLoadData from "../../hooks/useLoadData";
import "./style.scss";

const Offerings = ({
  searchPreset,
  setSearch,
  updateCount,
  handleResetQ,
  callback,
}) => {
  const [selectedFilters, setSelectedFilters] = useState({
    order_by: "RANKING",
    ...searchPreset,
  });

  const {
    loadData,
    items: offerings,
    setItems: setOfferings,
    total,
    setPageNumber,
  } = useLoadData({
    searchFunction: searchOfferingTopic,
    params: { ...selectedFilters },
  });

  const handleResetFilters = () => {
    setPageNumber(0);
    setOfferings([]);

    const initialFilter = {
      page_size: 24,
      page_number: 1,
      order_by: "RANKING",
    };
    setSelectedFilters(initialFilter);
    setSearch("offerings", initialFilter);
    handleResetQ("offerings");
  };

  const handleChangeFilter = (key, value) => {
    setPageNumber(0);
    setOfferings([]);

    setSelectedFilters({
      ...selectedFilters,
      order_by: "RANKING",
      [key]: value,
    });
    setSearch("offerings", {
      ...selectedFilters,
      [key]: value,
    });
  };

  useEffect(() => {
    updateCount("offerings", total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  useEffect(() => {
    setPageNumber(0);
    const newFilter = { ...selectedFilters, ...searchPreset };
    if (JSON.stringify(selectedFilters) === JSON.stringify(newFilter)) {
      return;
    }
    setSelectedFilters(newFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPreset]);

  return (
    <SearchView
      selectedFilters={selectedFilters}
      handleChangeFilter={handleChangeFilter}
      dataList={offerings}
      viewableFilters={filtersMyracle.OFFERINGS}
      isSerp
      handleResetFilters={handleResetFilters}
      callback={callback}
      documentType={entityType.OFFERING_TOPIC}
      loadData={loadData}
      total={total}
    />
  );
};

export default Offerings;
