import React, { useCallback, useState } from "react";
import MidArrow from "images/arrow.svg";
import Search from "images/icona-SEARCH.svg";
import CustomOption from "../CustomOption";

import "./style.scss";

const CustomSelect = ({
  value,
  items = [],
  onChange,
  label,
  isMultiple,
  isAutocomplete,
  closeOnSelect,
  onSearch,
  absoluteOptions,
}) => {
  const [openOptions, setOpenOptions] = useState(false);
  const [time, setTime] = useState();
  const [query, setQuery] = useState("");

  // TODO:  replace https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
  //const { matches: isMobile } = window.matchMedia("(max-width: 912px)");
  const width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
  const isMobile = width < 900;

  const handleOnChange = useCallback(
    (val, checked) => {
      if (onChange) {
        if (isMultiple) {
          if (!checked) {
            onChange(value.filter(e => e !== val));
          } else {
            onChange([...value, val]);
          }
        } else {
          if (!checked) {
            onChange(null);
          } else {
            onChange(val);
          }
        }
      }
      if (closeOnSelect) setOpenOptions(false);
      if (!isAutocomplete) setQuery("");
    },
    [closeOnSelect, isAutocomplete, isMultiple, onChange, value]
  );

  const handleSearch = event => {
    clearTimeout(time);
    event.persist();
    const val = event.target.value;
    if (val) {
      const timer = setTimeout(() => {
        if (onSearch && val) onSearch(val);
        setOpenOptions(true);
        clearTimeout(time);
      }, 500);
      setTime(timer);
    } else {
      setOpenOptions(false);
    }
  };

  const handleOptions = event => {
    setOpenOptions(!!event.currentTarget.value || true);
    setQuery(event.currentTarget.value);
  };

  const filteredItems = items
    ?.filter(i => {
      return (
        !isAutocomplete ||
        (i.label ? i.label : i.value)
          ?.toLowerCase()
          ?.includes(query.toLowerCase())
      );
    })
    ?.map(i => (
      <CustomOption
        key={i.id}
        onClick={handleOnChange}
        value={i.value}
        id={i.id}
        label={i.label}
        selected={
          Array.isArray(value) ? value.includes(i.value) : i.value === value
        }
      />
    ));

  const mappedItems = items?.map(i => (
    <CustomOption
      key={i.id}
      onClick={handleOnChange}
      value={i.value}
      id={i.id}
      type={i.type}
      code={i.code}
      selected={
        Array.isArray(value) ? value.includes(i.value) : i.value === value
      }
    />
  ));

  return (
    <div className="custom-select-wrapper">
      <div
        onClick={() => setOpenOptions(!openOptions)}
        className="custom-select-input">
        <div className="custom-select-label-wrapper">
          {isAutocomplete && !isMobile && (
            <input
              value={query}
              onChange={handleOptions}
              className="autocomplete-input"
              placeholder={label}
            />
          )}
          {isAutocomplete && isMobile && <div className="label">{label}</div>}
          {onSearch && (
            <input
              onKeyUp={handleSearch}
              className="autocomplete-input"
              placeholder={label}
            />
          )}
          {!isAutocomplete && !onSearch && <div className="label">{label}</div>}

          {Array.isArray(value) && value.length > 0 ? (
            <div className="filter-number">{value.length}</div>
          ) : !Array.isArray(value) && value ? (
            <div className="filter-number">1</div>
          ) : (
            ""
          )}
        </div>

        <div className="custom-select-input-arrow">
          <img src={MidArrow} alt="arrow-control" />
        </div>
      </div>

      {openOptions && isMobile && isAutocomplete && (
        <div
          className={`custom-select-options ${
            absoluteOptions ? "absolute" : ""
          }`}>
          <div className="custom-select-input">
            <div
              className="custom-select-input-arrow"
              onClick={() => setOpenOptions(!openOptions)}>
              <img src={MidArrow} alt="arrow-control" />
            </div>
            <div className="custom-select__input-container">
              <input
                value={query}
                onChange={handleOptions}
                className="autocomplete-input"
                placeholder={label}
              />
              <img className="input-search-icon" src={Search} alt="" />
            </div>
          </div>
          {items[0]?.type === "title" ? mappedItems : filteredItems}
        </div>
      )}
      {openOptions && (!isAutocomplete || !isMobile) && (
        <div
          className={`custom-select-options ${
            absoluteOptions ? "absolute" : ""
          }`}>
          <div
            onClick={() => setOpenOptions(!openOptions)}
            className="custom-select-input">
            <div className="custom-select-input-arrow">
              <img src={MidArrow} alt="arrow-control" />
            </div>
            {label}
          </div>
          {items[0]?.type === "title" ? mappedItems : filteredItems}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
