import React, { useCallback, useMemo, useState } from "react";
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { HashLink } from "react-router-hash-link";
import arrowLeft from "images/red-arrow-left.svg";
import arrowRight from "images/red-arrow-right.svg";

import 'react-horizontal-scrolling-menu/dist/styles.css';
import "./style.scss";

const AnchorTabs = () => {
  const [active, setActive] = useState("who");

  const click = useCallback(id => {
    setActive(id);
  }, []);

  const buttons = useMemo(
    () => [
      {
        label: "What is Myracle and how it works",
        id: "who",
      },
      /* TODO: scommentare quando arriveranno le info dal cliente per il popolamento di questa sezione
      {
        label: "What Myracle does",
        id: "what",
      },*/
      {
        label: "Contacts",
        id: "contacts",
      },
    ],
    []
  );

  function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);
    return (
      <button
        disabled={isFirstItemVisible}
        onClick={() => scrollPrev("smooth","center")}
        className="anchor-tabs__arrows__left"
        aria-label="arrow left"
      >
        <img src={arrowLeft} alt="arrow left" height="10" width="6" />
      </button>
    );
  }

  function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
    return (
      <button
        disabled={isLastItemVisible}
        onClick={() => scrollNext("smooth","center")}
        className="anchor-tabs__arrows__right"
        aria-label="arrow right"
      >
        <img src={arrowRight} alt="arrow right" height="10" width="6" />
      </button>
    );
  }


  return (
    <div className="anchor-tabs">
      <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
        {buttons.map(({ label, id }, index) => (
            <HashLink
              key={id}
              className={active === id ? "active" : ""}
              to={`#${id}`}
              onClick={() => click(id)}
              scroll={el => el.scrollIntoView({ behavior: "smooth" })}>
              {label}
            </HashLink>
        ))}
      </ScrollMenu>
    </div>
  );
};

AnchorTabs.propTypes = {};

export default AnchorTabs;
