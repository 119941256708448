import { apis, serviceInterface } from "./config";

export const getQualification = (code, isDraft) => {
  return serviceInterface.get(
    `${apis.QUAL}/${code}${isDraft ? "?versionType=DRAFT" : ""}`
  );
};

export const getQualificationNoGraphic = (code, isDraft) => {
  return serviceInterface.get(
    `${apis.QUAL}/${code}?no_graphic=true${isDraft ? "&versionType=DRAFT" : ""}`
  );
};

export const getRelated = id => {
  return serviceInterface.get(`${apis.QUAL}/${id}/links`);
};

export const getPPTTemplatesNoGraphic = (name, type) =>
  serviceInterface.get(
    `template?no_graphic=true${name ? "&name=" + name : ""}${
      type ? "&type=" + type : ""
    }`
  );

export const getPPTTemplates = () => {
  return serviceInterface.get("template");
};

export const generateSlide = (code, templateId) => {
  return serviceInterface.get(
    `${apis.QUAL}/${code}/generated-slide-download?templateId=${templateId}`
  );
};

export const saveAsDraft = params => {
  return serviceInterface.post(apis.QUAL, { ...params });
};

export const update = (code, params) => {
  return serviceInterface.put(`${apis.QUAL}/${code}`, { ...params });
};

export const publish = code => {
  return serviceInterface.patch(`${apis.QUAL}/${code}/publish`);
};

export const unPublish = code => {
  return serviceInterface.patch(`${apis.QUAL}/${code}/unpublish`);
};

export const getPptDownload = async (code, isDraft = false) => {
  try {
    return serviceInterface
      .get(`${apis.QUAL}/${code}/ppt${isDraft ? "?versionType=DRAFT" : ""}`, {
        responseType: "arraybuffer",
      })
      .then(async res => {
        if (res && res.data) {
          const base64 = Buffer.from(res.data, "binary").toString("base64");
          return base64;
        }
      });
  } catch (err) {
    console.log("Error downloading file", err);
  }
};

export const deleteQualification = code => {
  return serviceInterface.delete(`${apis.QUAL}/${code}`);
};
